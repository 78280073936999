import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Switch, Route } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../../constants/routes';
import { fetchDocs, fetchDoc, deleteDoc } from '../../actions';
import {
  getDocs,
  getIsFetching,
  getFetchError,
  getDoc,
  getDocsById,
  getProfile,
  getAuthUser,
} from '../../reducers';
import Spinner from '../Spinner';
import Error from '../Error';
import DeleteDialog from '../DeleteDialog';
import AlgoliaAPI from '../../algolia/AlgoliaAPI';

import InstitutionList from './InstitutionList';
import InstitutionItem from './InstitutionItem';
import InstitutionForm from './InstitutionForm';
import { useFirebase } from '../../hooks';

export const institutionsPath = '/institutions';
export const institutionsIndex = new AlgoliaAPI('institutions');
export const getInstitutions = query => state => ({
  docs: getDocs(state, query),
  byId: getDocsById(state, query),
  isFetching: getIsFetching(state, query),
  fetchError: getFetchError(state, query),
});

function Institutions() {
  const { id } = useParams();
  const profile = useSelector(getProfile);
  const authUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const query = useMemo(() => {
    let where;
    if (profile.role !== 'admin') {
      where = [['manager', '==', firebase.user(authUser.uid)]];
    }
    return {
      path: institutionsPath,
      where,
    };
  }, [profile.role, firebase, authUser]);
  useEffect(() => {
    if (id) {
      dispatch(fetchDoc(query, id));
    } else {
      dispatch(fetchDocs(query));
    }
  }, [dispatch, id, query]);

  const instituionsSelector = useCallback(
    state => getInstitutions(query)(state),
    [query]
  );
  const { docs, isFetching, fetchError } = useSelector(instituionsSelector);

  const instituionSelector = useCallback(state => getDoc(state, query, id), [
    id,
    query,
  ]);
  const doc = useSelector(instituionSelector);

  const { addToast } = useToasts();
  const [forDelete, setForDelete] = useState(null);
  async function handleDelete() {
    try {
      await dispatch(deleteDoc(query, forDelete.id));
      setForDelete(null);
      addToast('Kurum başarıyla silindi', { appearance: 'success' });
    } catch (error) {
      addToast(error.message, { appearance: 'success' });
    }
  }

  if (isFetching || (id && !doc && !fetchError)) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <>
      <DeleteDialog
        isOpen={!!forDelete}
        onDelete={() => handleDelete(forDelete)}
        onToggle={() => setForDelete(null)}
        itemName={forDelete && forDelete.get('name')}
      />
      <Switch>
        <Route exact path={ROUTES.INSTITUTIONS}>
          <InstitutionList docs={docs || []} onDeleteClick={setForDelete} />
        </Route>
        <Route exact path={`${ROUTES.INSTITUTIONS}/:id`}>
          <InstitutionItem doc={doc} />
        </Route>
        <Route exact path={`${ROUTES.INSTITUTIONS}/:id/duzenle`}>
          <InstitutionForm doc={doc} />
        </Route>
      </Switch>
    </>
  );
}

export default Institutions;
export { InstitutionList, InstitutionItem, InstitutionForm };
