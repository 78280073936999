const INITIAL_STATE = { isFetching: false, error: null };

const profile = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'SET_AUTH_USER':
    case 'SET_PROFILE':
      return {
        ...INITIAL_STATE,
        ...action.profile,
      };
    case 'UPDATE_PROFILE_REQUEST':
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    case 'UPDATE_PROFILE_SUCCESS':
      return {
        ...state,
        ...action.profile,
        isFetching: false,
      };
    case 'UPDATE_PROFILE_FAILURE':
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default profile;

export const getProfile = state => state;
export const getProfileIsFetching = state => state.isFetching;
