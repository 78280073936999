import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Switch, Route } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import { fetchDocs, fetchDoc, deleteDoc } from '../../actions';
import {
  getDocs,
  getIsFetching,
  getFetchError,
  getDoc,
  getDocsById,
  getIds,
  getProfile,
  getAuthUser,
} from '../../reducers';
import Spinner from '../Spinner';
import Error from '../Error';
import DeleteDialog from '../DeleteDialog';
import AlgoliaAPI from '../../algolia/AlgoliaAPI';

import UserList from './UserList';
import UserItem from './UserItem';
import UserForm from './UserForm';
import UserGames from './UserGames';
import GameCharts from './GameCharts';
import { useFirebase } from '../../hooks';

export const usersPath = '/users';
export const usersIndex = new AlgoliaAPI('users');
export const getUsers = query => state => ({
  docs: getDocs(state, query),
  byId: getDocsById(state, query),
  ids: getIds(state, query),
  isFetching: getIsFetching(state, query),
  fetchError: getFetchError(state, query),
});

function Users({ selectedRole, path }) {
  const { id } = useParams();
  const profile = useSelector(getProfile);
  const authUser = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const query = useMemo(() => {
    let where;
    if (selectedRole === 'student') {
      where = [['role', '==', 'student']];
      if (profile.role === 'teacher' || profile.role === 'specialist') {
        where.push(['mentors', 'array-contains', firebase.user(authUser.uid)]);
      }
    } else {
      const roles = ['teacher', 'specialist', 'manager'];
      if (profile.role === 'admin') {
        roles.push('admin');
      }
      where = [['role', 'in', roles]];
    }
    if (profile.role !== 'admin') {
      where.push(['institution', '==', profile.institution]);
    }
    return {
      path: usersPath,
      where,
    };
  }, [selectedRole, profile.role, profile.institution, authUser.uid, firebase]);
  useEffect(() => {
    if (id) {
      dispatch(fetchDoc(query, id));
    } else {
      dispatch(fetchDocs(query));
    }
  }, [dispatch, id, query]);

  const usersSelector = useCallback(state => getUsers(query)(state), [query]);
  const { docs, isFetching, fetchError } = useSelector(usersSelector);

  const userSelector = useCallback(state => getDoc(state, query, id), [
    id,
    query,
  ]);
  const doc = useSelector(
    userSelector
    // (u1, u2) => u1.id === u2.id && u1.get('instution') === u2.get('instution')
  );

  const { addToast } = useToasts();
  const [forDelete, setForDelete] = useState(null);
  async function handleDelete() {
    try {
      await dispatch(deleteDoc(query, forDelete.id));
      setForDelete(null);
      addToast('Silme islemi tamamlandi.', { appearance: 'success' });
    } catch (error) {
      addToast(error.message, { appearance: 'success' });
    }
  }

  if (isFetching || (id && !doc && !fetchError)) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <>
      <DeleteDialog
        isOpen={!!forDelete}
        onDelete={() => handleDelete(forDelete)}
        onToggle={() => setForDelete(null)}
        itemName={forDelete && forDelete.get('name')}
      />
      <Switch>
        <Route exact path={path}>
          <UserList
            docs={docs || []}
            onDeleteClick={setForDelete}
            selectedRole={selectedRole}
            path={path}
          />
        </Route>
        <Route exact path={`${path}/:id`}>
          <UserItem
            doc={doc}
            onDeleteClick={setForDelete}
            selectedRole={selectedRole}
            path={path}
          />
        </Route>
        <Route exact path={`${path}/:id/duzenle`}>
          <UserForm doc={doc} selectedRole={selectedRole} path={path} />
        </Route>
      </Switch>
    </>
  );
}

export default Users;

export { UserList, UserItem, UserForm, UserGames, GameCharts };
