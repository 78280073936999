import React from 'react';
import { useSelector } from 'react-redux';

import Dialog from '../Dialog';
import { scratchURL, defaultGameId } from '../../config';
import { getAuthUser } from '../../reducers';

export default function ScratchInfoDialog({ isOpen, onToggle, gameId }) {
  const authUser = useSelector(getAuthUser);

  const buttons = [
    <button key="close" className="btn" onClick={onToggle}>
      İptal
    </button>,
    <a
      key="confirm"
      className="btn btn-warning"
      href={`${scratchURL}?userId=${authUser.uid}&gameId=${
        gameId ? gameId : defaultGameId
      }&mode=editor`}
      target="__blank"
      onClick={onToggle}
    >
      Anladım
    </a>,
  ];

  return (
    <Dialog
      title="Oyun Dosyası (.sbx)"
      buttons={buttons}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      Oyun tasarlamak için Scratch sayfasına yönlendirileceksiniz. Oyun tasarla
      ekranında tasarladığınız oyunun sbx uzantılı dosyasını bu sisteme oyun
      ekle sayfasından yüklerek yayınlanmasını sağlayabilirsiniz.
    </Dialog>
  );
}
