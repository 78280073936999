/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers';

import { useSelector } from 'react-redux';
import ScratchInfoDialog from '../../../../../components/Games/ScratchInfoDialog';
import { getProfile } from '../../../../../reducers';

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = url => {
    return checkIsActive(location, url)
      ? ' menu-item-active menu-item-open '
      : '';
  };

  const profile = useSelector(getProfile);
  const role = profile.role;

  const [showScratchInfo, setShowScratchInfo] = useState(false);

  return (
    <>
      <ScratchInfoDialog
        isOpen={showScratchInfo}
        onToggle={() => setShowScratchInfo(false)}
        gameId={null}
      />

      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard')}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Panel</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {role === 'admin' && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Kurumlar</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                '/kurumlar'
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/kurumlar">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Home/Building.svg')}
                  />
                </span>
                <span className="menu-text">Kurum İşlemleri</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Kurum İşlemleri</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive('/kurumlar')}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/kurumlar">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Layout/Layout-top-panel-6.svg'
                          )}
                        />
                      </span>
                      <span className="menu-text">Kurum Listesi</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/kurumlar/ekle'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/kurumlar/ekle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
                        />
                      </span>
                      <span className="menu-text">Kurum Ekle</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}
          </>
        )}

        {(role === 'admin' || role === 'manager') && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Öğretmenler</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                '/ogretmenler'
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/ogretmenler">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Clothes/Tie.svg')}
                  />
                </span>
                <span className="menu-text">Öğretmen İşlemleri</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Öğretmen İşlemleri</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive('/ogretmenler')}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/ogretmenler">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Layout/Layout-top-panel-6.svg'
                          )}
                        />
                      </span>
                      <span className="menu-text">Öğretmen Listesi</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/ogretmenler/ekle'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/ogretmenler/ekle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
                        />
                      </span>
                      <span className="menu-text">Öğretmen Ekle</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}
          </>
        )}

        {role !== 'student' && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Öğrenciler</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                '/ogrenciler'
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/ogrenciler">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/General/User.svg')}
                  />
                </span>
                <span className="menu-text">Öğrenci İşlemleri</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Öğrenci İşlemleri</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive('/ogrenciler')}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/ogrenciler">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Layout/Layout-top-panel-6.svg'
                          )}
                        />
                      </span>
                      <span className="menu-text">Öğrenci Listesi</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {(role === 'admin' || role === 'manager') && (
                    <>
                      {/*begin::2 Level*/}
                      <li
                        className={`menu-item ${getMenuItemActive(
                          '/ogrenciler/ekle'
                        )}`}
                        aria-haspopup="true"
                      >
                        <NavLink className="menu-link" to="/ogrenciler/ekle">
                          <span className="svg-icon menu-icon">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Code/Plus.svg'
                              )}
                            />
                          </span>
                          <span className="menu-text">Öğrenci Ekle</span>
                        </NavLink>
                      </li>
                      {/*end::2 Level*/}
                    </>
                  )}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}
          </>
        )}

        {role !== 'student' && (
          <>
            {/* begin::section */}
            <li className="menu-section ">
              <h4 className="menu-text">Oyunlar</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 Level*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                '/oyunlar'
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/oyunlar">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl('/media/svg/icons/Devices/Gamepad1.svg')}
                  />
                </span>
                <span className="menu-text">Oyun İşlemleri</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Oyun İşlemleri</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive('/oyunlar')}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/oyunlar">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            '/media/svg/icons/Layout/Layout-top-panel-6.svg'
                          )}
                        />
                      </span>
                      <span className="menu-text">Oyun Listesi</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li className={`menu-item`} aria-haspopup="true">
                    <a
                      className="menu-link"
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setShowScratchInfo(true);
                      }}
                    >
                      <span className="svg-icon menu-icon">
                        <img
                          style={{ width: '24px', height: '24px' }}
                          alt="Oyun tasarla"
                          src={toAbsoluteUrl('/media/scratch-png.png')}
                        />
                      </span>
                      <span className="menu-text">Oyun Tasarla</span>
                    </a>
                  </li>
                  {/*end::2 Level*/}

                  {/*begin::2 Level*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      '/oyunlar/ekle'
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/oyunlar/ekle">
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl('/media/svg/icons/Code/Plus.svg')}
                        />
                      </span>
                      <span className="menu-text">Oyun Ekle</span>
                    </NavLink>
                  </li>
                  {/*end::2 Level*/}
                </ul>
              </div>
            </li>
            {/*end::1 Level*/}
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
