import firebase from 'firebase/app';

// https://stackoverflow.com/a/9382383/1306183
export function decycle(obj, stack = []) {
  if (!obj || typeof obj !== 'object') return obj;

  if (obj instanceof firebase.firestore.DocumentReference) {
    return null;
  }

  if (stack.includes(obj)) return null;

  let s = stack.concat([obj]);

  return Array.isArray(obj)
    ? obj.map(x => decycle(x, s)).filter(v => v !== null)
    : Object.fromEntries(
        Object.entries(obj)
          .map(([k, v]) => [k, decycle(v, s)])
          .filter(([k, v]) => v !== null)
      );
}
