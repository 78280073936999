import React from 'react';
import {
  QuickPanel,
  Performance,
  WeeklyPlay,
  StatsWidget12,
  GamesTable,
  Students,
} from './widgets';

export default function Dashboard() {
  return (
    <>
      <div className="row">
        <div className="col-lg-6 col-xxl-4">
          <QuickPanel className="card-stretch gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4">
          <WeeklyPlay className="card-stretch card-stretch-half gutter-b" />
          <StatsWidget12 className="card-stretch card-stretch-half gutter-b" />
        </div>

        <div className="col-lg-6 col-xxl-4 order-1 order-xxl-2">
          <Students className="card-stretch gutter-b" />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Performance className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <GamesTable className="card-stretch gutter-b" />
        </div>
      </div>
    </>
  );
}
