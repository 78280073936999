import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { signOut } from '../actions';

export default function SignOut() {
  const dispatch = useDispatch();
  const history = useHistory();
  dispatch(signOut()).then(() => {
    history.push(ROUTES.SIGNIN);
  });
  return null;
}
