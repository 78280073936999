import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Switch, Route } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../../constants/routes';
import { fetchDocs, fetchDoc, deleteDoc } from '../../actions';
import {
  getDocs,
  getIsFetching,
  getFetchError,
  getDoc,
  getDocsById,
} from '../../reducers';
import Spinner from '../Spinner';
import Error from '../Error';
import DeleteDialog from '../DeleteDialog';
import AlgoliaAPI from '../../algolia/AlgoliaAPI';

import GameList from './GameList';
import GameItem from './GameItem';
import GameForm from './GameForm';
import ScratchInfoDialog from './ScratchInfoDialog';

export const gamesPath = '/games';
export const gamesIndex = new AlgoliaAPI('games');
export const getGames = query => state => ({
  docs: getDocs(state, query),
  byId: getDocsById(state, query),
  isFetching: getIsFetching(state, query),
  fetchError: getFetchError(state, query),
});

function Games() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const query = useMemo(
    () => ({
      path: gamesPath,
    }),
    []
  );
  useEffect(() => {
    if (id) {
      dispatch(fetchDoc(query, id));
    } else {
      dispatch(fetchDocs(query));
    }
  }, [dispatch, id, query]);

  const gamesSelector = useCallback(state => getGames(query)(state), [query]);
  const { docs, isFetching, fetchError } = useSelector(gamesSelector);

  const gameSelector = useCallback(state => getDoc(state, query, id), [
    id,
    query,
  ]);
  const doc = useSelector(gameSelector);

  const { addToast } = useToasts();
  const [forDelete, setForDelete] = useState(null);
  async function handleDelete() {
    try {
      await dispatch(deleteDoc(query, forDelete.id));
      setForDelete(null);
      addToast('Oyun başarıyla silindi', { appearance: 'success' });
    } catch (error) {
      addToast(error.message, { appearance: 'success' });
    }
  }

  const [showScratchInfo, setShowScratchInfo] = useState(false);

  if (isFetching || (id && !doc && !fetchError)) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <>
      <DeleteDialog
        isOpen={!!forDelete}
        onDelete={() => handleDelete(forDelete)}
        onToggle={() => setForDelete(null)}
        itemName={forDelete && forDelete.get('name')}
      />
      <ScratchInfoDialog
        isOpen={showScratchInfo}
        onToggle={() => setShowScratchInfo(false)}
        gameId={doc && doc.id}
      />
      <Switch>
        <Route exact path={ROUTES.GAMES}>
          <GameList docs={docs || []} onDeleteClick={setForDelete} />
        </Route>
        <Route exact path={`${ROUTES.GAMES}/:id`}>
          <GameItem doc={doc} />
        </Route>
        <Route exact path={`${ROUTES.GAMES}/:id/duzenle`}>
          <GameForm doc={doc} onScratchClick={() => setShowScratchInfo(true)} />
        </Route>
      </Switch>
    </>
  );
}

export default Games;
export { GameList, GameItem, GameForm };
