import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { ROLES, BRANCHES, DISABILITIES } from './constants';
import COLORS from '../../constants/colors';
import * as ROUTES from '../../constants/routes';
import { getProfile } from '../../reducers';

import { UserGames, GameCharts } from '.';

function UserItem({ doc, onDeleteClick, selectedRole, path }) {
  const profile = useSelector(getProfile);
  const role = profile.role;
  const [institution, setInstitution] = useState();
  useEffect(() => {
    let cancel = false;
    // eslint-disable-next-line no-unused-expressions
    doc
      .get('institution')
      ?.get()
      .then(institution => {
        if (!cancel) {
          setInstitution(institution);
        }
      });
    return () => {
      cancel = true;
    };
  }, [doc]);

  const [students, setStudents] = useState([]);
  useEffect(() => {
    if (selectedRole === 'student') {
      return;
    }
    let cancel = false;
    Promise.all(
      (doc.get('students') || []).map(ref => {
        return ref.get();
      })
    ).then(students => {
      if (!cancel) {
        setStudents(students);
      }
    });
    return () => {
      cancel = true;
    };
  }, [doc, selectedRole]);

  const [mentors, setMentors] = useState([]);
  useEffect(() => {
    if (selectedRole !== 'student') {
      return;
    }
    let cancel = false;
    Promise.all(
      (doc.get('mentors') || []).map(ref => {
        return ref.get();
      })
    ).then(mentors => {
      if (!cancel) {
        setMentors(mentors);
      }
    });
    return () => {
      cancel = true;
    };
  }, [doc, selectedRole]);

  function handleDelete(event, doc) {
    event.preventDefault();
    onDeleteClick(doc);
  }

  const history = useHistory();
  function handleStudentClick(id) {
    history.push(`${ROUTES.STUDENTS}/${id}`);
  }

  function handleMentorClick(id) {
    history.push(`${ROUTES.TEACHERS}/${id}`);
  }

  const [numDesignedGames, setNumDesignedGames] = useState(0);
  const [sessions, setSessions] = useState([]);

  return (
    <div className="container">
      <div className="card card-custom gutter-b">
        <div className="card-body">
          <div className="d-flex mb-9">
            <div className="flex-shrink-0 mr-7 mt-lg-0 mt-3">
              <div className="symbol symbol-50 symbol-lg-120">
                <img src={doc.get('imageURL')} alt="Profil resmi" />
              </div>
              <div className="symbol symbol-50 symbol-lg-120 symbol-primary d-none">
                <span className="font-size-h3 symbol-label font-weight-boldest">
                  {`${doc.get('firstName')[0]}${doc.get('lastName')[0]}`}
                </span>
              </div>
            </div>
            <div className="flex-grow-1">
              <div className="d-flex justify-content-between flex-wrap mt-1">
                <div className="d-flex mr-3">
                  <Link
                    className="text-dark-75 text-hover-primary font-size-h5 font-weight-bold mr-3"
                    to={`${path}/${doc.id}`}
                  >
                    {doc.get('fullname')}
                  </Link>
                </div>
                <div className="my-lg-0 my-3">
                  {(role === 'admin' || role === 'manager') && (
                    <>
                      <Link
                        className="btn btn-sm btn-light-success font-weight-bolder text-uppercase mr-3"
                        to={`${path}/${doc.id}/duzenle`}
                      >
                        {selectedRole !== 'student' && 'Öğrenci ata / '}
                        Düzenle
                      </Link>
                      <a
                        href="#sil"
                        className="btn btn-sm btn-danger font-weight-bolder text-uppercase"
                        onClick={e => handleDelete(e, doc)}
                      >
                        Sil
                      </a>
                    </>
                  )}
                </div>
              </div>
              <div className="d-flex flex-wrap justify-content-between mt-1">
                <div className="d-flex flex-column flex-grow-1 pr-8">
                  <div className="d-flex flex-wrap mb-4">
                    <span className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-new-email mr-2 font-size-lg"></i>
                      {doc.get('email')}
                    </span>
                    <span className="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                      <i className="flaticon2-calendar-3 mr-2 font-size-lg"></i>
                      {ROLES[doc.get('role')]}
                    </span>
                    <span className="text-dark-50 text-hover-primary font-weight-bold">
                      <i className="flaticon2-placeholder mr-2 font-size-lg"></i>
                      {institution?.get('name')}
                    </span>
                  </div>
                  <span className="font-weight-bold text-dark-50">
                    {selectedRole === 'student'
                      ? doc.get('disabilities')?.map(item => (
                          <span
                            key={item}
                            className={`badge badge-${COLORS[item]} mb-1 mr-1`}
                          >
                            {DISABILITIES[item]}
                          </span>
                        ))
                      : doc.get('branches')?.map(item => (
                          <span
                            key={item}
                            className={`badge badge-${COLORS[item]} mb-1 mr-1`}
                          >
                            {BRANCHES[item]}
                          </span>
                        ))}
                  </span>
                </div>
                {selectedRole === 'student' && (
                  <div className="d-flex align-items-center w-25 flex-fill float-right mt-lg-12 mt-8">
                    <span className="font-weight-bold text-dark-75">
                      Progress
                    </span>
                    <div className="progress progress-xs mx-3 w-100">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{ width: '63%' }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                    <span className="font-weight-bolder text-dark">78%</span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="separator separator-solid"></div>
          <div className="d-flex align-items-center flex-wrap mt-8">
            <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
              <span className="mr-4">
                <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
              </span>
              <div className="d-flex flex-column text-dark-75">
                <span className="font-weight-bolder font-size-sm">
                  {selectedRole === 'student'
                    ? 'Atanan oyun sayısı'
                    : 'Tasarladığı oyun sayısı'}
                </span>
                <span className="font-weight-bolder font-size-h5">
                  {selectedRole === 'student'
                    ? doc.get('assignedGames')?.length || 0
                    : numDesignedGames}
                </span>
              </div>
            </div>
            {selectedRole === 'student' && (
              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span className="mr-4">
                  <i className="flaticon-confetti display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">
                    Oynama sayısı
                  </span>
                  <span className="font-weight-bolder font-size-h5">
                    {sessions.length}
                  </span>
                </div>
              </div>
            )}
            {selectedRole !== 'student' && (
              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span className="mr-4">
                  <i className="flaticon-file-2 display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column text-dark-75">
                  <span className="font-weight-bolder font-size-sm">
                    Öğrenci sayısı
                  </span>
                  <span className="font-weight-bolder font-size-h5">
                    {doc.get('students')?.length || 0}
                  </span>
                </div>
              </div>
            )}
            {selectedRole === 'student' && (
              <div className="d-flex align-items-center flex-lg-fill mr-5 mb-2">
                <span className="mr-4">
                  <i className="flaticon-pie-chart display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="d-flex flex-column flex-lg-fill">
                  <span className="text-dark-75 font-weight-bolder font-size-sm">
                    Performans
                  </span>
                  <a
                    href="#grafikler"
                    className="text-primary font-weight-bolder"
                  >
                    Grafikler
                  </a>
                </div>
              </div>
            )}
            {selectedRole !== 'student' && (
              <div className="d-flex align-items-center flex-lg-fill mb-2 float-left">
                <span className="mr-4">
                  <i className="flaticon-network display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="symbol-group symbol-hover">
                  {students.map(student => (
                    <div
                      key={student.id}
                      className="symbol symbol-30 symbol-circle"
                      data-toggle="tooltip"
                      title={student.get('fullname')}
                    >
                      <img
                        alt="Profil resmi"
                        src={student.get('imageURL')}
                        onClick={() => handleStudentClick(student.id)}
                      />
                    </div>
                  ))}
                  {/* <div className="symbol symbol-30 symbol-circle symbol-light">
                    <span className="symbol-label font-weight-bold">5+</span>
                  </div> */}
                </div>
              </div>
            )}
            {selectedRole === 'student' && (
              <div className="d-flex align-items-center flex-lg-fill mb-2 float-left">
                <span className="mr-4">
                  <i className="flaticon-network display-4 text-muted font-weight-bold"></i>
                </span>
                <div className="symbol-group symbol-hover">
                  {mentors.map(mentor => (
                    <div
                      key={mentor.id}
                      className="symbol symbol-30 symbol-circle"
                      data-toggle="tooltip"
                      title={mentor.get('fullname')}
                    >
                      <img
                        alt="Profil resmi"
                        src={mentor.get('imageURL')}
                        onClick={() => handleMentorClick(mentor.id)}
                      />
                    </div>
                  ))}
                  {/* <div className="symbol symbol-30 symbol-circle symbol-light">
                    <span className="symbol-label font-weight-bold">5+</span>
                  </div> */}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <UserGames
        key={`games${doc.id}`}
        user={doc}
        onGamesFetched={setNumDesignedGames}
        sessions={sessions}
      />
      <GameCharts
        key={`charts${doc.id}`}
        user={doc}
        onSessionsFetched={setSessions}
      />
    </div>
  );
}

export default UserItem;
