import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import { I18nProvider } from './_metronic/i18n';
import { LayoutSplashScreen, MaterialThemeProvider } from './_metronic/layout';

import {
  ToastProvider,
  DefaultToastContainer,
  DefaultToast,
} from 'react-toast-notifications';

import { FirebaseContext } from './firebase';

function ToastContainer(props) {
  return <DefaultToastContainer {...props} className="my-toast-container" />;
}

function Toast({ children, ...props }) {
  return (
    <DefaultToast {...props} className="my-toast">
      {children}
    </DefaultToast>
  );
}

export default function App({ store, api, basename }) {
  return (
    <Provider store={store}>
      <FirebaseContext.Provider value={api}>
        <ToastProvider components={{ ToastContainer, Toast }} autoDismiss>
          <React.Suspense fallback={<LayoutSplashScreen />}>
            <Router basename={basename}>
              <MaterialThemeProvider>
                <I18nProvider>
                  <Routes />
                </I18nProvider>
              </MaterialThemeProvider>
            </Router>
          </React.Suspense>
        </ToastProvider>
      </FirebaseContext.Provider>
    </Provider>
  );
}
