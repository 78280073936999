import { combineReducers } from 'redux';

import createCollection, * as fromCollection from './createCollection';
import auth, * as fromAuth from './auth';

const collections = combineReducers({
  '/users': createCollection('/users'),
  '/games': createCollection('/games'),
  '/sessions': createCollection('/sessions'),
  '/institutions': createCollection('/institutions'),
});

export default combineReducers({ auth, collections });

export const getIds = (state, query) =>
  fromCollection.getIds(state.collections, query);
export const getDocs = (state, query) =>
  fromCollection.getDocs(state.collections, query);
export const getDoc = (state, query, id) =>
  fromCollection.getDoc(state.collections, query, id);
export const getDocsById = (state, query) =>
  fromCollection.getDocsById(state.collections, query);
export const getIsFetching = (state, query) =>
  fromCollection.getIsFetching(state.collections, query);
export const getIsAdding = (state, query) =>
  fromCollection.getIsAdding(state.collections, query);
export const getIsUpdating = (state, query) =>
  fromCollection.getIsUpdating(state.collections, query);
export const getIsDeleting = (state, query) =>
  fromCollection.getIsDeleting(state.collections, query);
export const getFetchError = (state, query) =>
  fromCollection.getFetchError(state.collections, query);
export const getAddError = (state, query) =>
  fromCollection.getAddError(state.collections, query);
export const getUpdateError = (state, query) =>
  fromCollection.getUpdateError(state.collections, query);
export const getDeleteError = (state, query) =>
  fromCollection.getDeleteError(state.collections, query);

export const getAuthUser = state => fromAuth.getAuthUser(state.auth);
export const getSignInError = state => fromAuth.getSignInError(state.auth);
export const getSignInFetching = state =>
  fromAuth.getSignInFetching(state.auth);
export const getSignUpError = state => fromAuth.getSignUpError(state.auth);
export const getSignUpFetching = state =>
  fromAuth.getSignUpFetching(state.auth);
export const getProfile = state => fromAuth.getProfile(state.auth);
export const getProfileIsFetching = state =>
  fromAuth.getProfileIsFetching(state.auth);
