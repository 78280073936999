import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useForm, Controller } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import ReactDatePicker from 'react-datepicker';

import { useFirebase } from '../../hooks';
import { addDoc, updateDoc, fetchDocs } from '../../actions';
import { getAuthUser, getProfile } from '../../reducers';
import Button from '../Button';
import { getInstitutions, institutionsPath } from '../Institutions';
import Spinner from '../Spinner';
import Error from '../Error';
import { BRANCHES, ROLES, STATUSES, DISABILITIES, GENDERS } from './constants';
import { usersIndex, usersPath, getUsers } from '.';
import { getPermittedRoles } from './helpers';

function UserForm({ doc, selectedRole, path }) {
  const profile = useSelector(getProfile);
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: doc
      ? {
          ...doc.data(),
          dateOfBirth: doc.get('dateOfBirth').toDate(),
          institution: doc.get('institution')?.id,
          students: (doc.get('students') || []).map(ref => ref.id),
        }
      : {},
  });

  useEffect(() => {
    register({ name: 'imagePath' }, { required: true });
    register({ name: 'imageURL' }, { required: true });
    register({ name: 'dateOfBirth' }, { required: true });
  }, [register]);

  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [image, setImage] = useState();
  function loadImage(selectedFile) {
    const reader = new FileReader();
    reader.onload = event => {
      setImage(event.target.result);
    };
    reader.onerror = event => {
      addToast('Selected file could not read.', {
        appearance: 'error',
      });
      reader.abort();
    };
    reader.readAsDataURL(selectedFile);
  }

  async function handleImageChange(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile.size > 10 * 1024 * 1024) {
      setError('imagePath', 'fileSize');
      return;
    }
    clearErrors('imagePath');

    setUploading(true);
    loadImage(selectedFile);
    const { filePath, fileURL } = await uploadFile(selectedFile, 'imagePath');
    setValue('imagePath', filePath);
    setValue('imageURL', fileURL);
    setUploading(false);
  }

  const firebase = useFirebase();
  const authUser = useSelector(getAuthUser);
  async function uploadFile(file, fieldName, path = '') {
    const storageRef = firebase.storage.ref();

    // upload file if exists
    const userId = authUser.uid;
    const filePath =
      doc && doc.get(fieldName)
        ? doc.get(fieldName)
        : `${userId}${path}/${uuidv4()}`;
    const fileRef = storageRef.child(filePath);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
    return { filePath, fileURL };
  }

  const dispatch = useDispatch();

  // get all institutions
  const institutionsQuery = useMemo(() => ({ path: institutionsPath }), []);
  useEffect(() => {
    dispatch(fetchDocs(institutionsQuery));
  }, [dispatch, institutionsQuery]);
  const {
    docs: institutions,
    byId: institutionsById,
    isFetching: institutionsIsFetching,
    fetchError: institutionsFetchError,
  } = useSelector(getInstitutions(institutionsQuery));

  // get all students
  const studentsQuery = useMemo(() => {
    const where = [['role', '==', 'student']];
    if (profile.role !== 'admin') {
      where.push(['institution', '==', profile.institution]);
    }
    return {
      id: '/students',
      path: usersPath,
      where,
    };
  }, [profile.role, profile.institution]);
  useEffect(() => {
    dispatch(fetchDocs(studentsQuery));
  }, [dispatch, studentsQuery]);
  const userSelector = useCallback(state => getUsers(studentsQuery)(state), [
    studentsQuery,
  ]);
  const {
    docs: students,
    byId: studentsById,
    ids: studentsIds,
    isFetching: studentsIsFetching,
    fetchError: studentsFetchError,
  } = useSelector(userSelector);

  async function onSubmit(data) {
    try {
      setLoading(true);

      if (selectedRole === 'student') {
        data.role = 'student';
      }
      data.fullname = `${data.firstName} ${data.lastName}`;
      let institution;
      if (profile.role === 'admin') {
        institution = data.institution;
        data.institution = institutionsById[institution].ref;
      } else {
        institution = profile.institution.id;
        data.institution = institutionsById[institution].ref;
      }
      let students;
      if (selectedRole !== 'student') {
        students = data.students;
        data.students = students.map(id => studentsById[id].ref);
      }

      let newDoc;
      let message;
      const query = { path: usersPath };
      if (doc) {
        newDoc = await dispatch(updateDoc(query, doc.id, data));
        await usersIndex.update(newDoc.id, newDoc.data());
        message = 'Güncelleme başarılı';
      } else {
        newDoc = await dispatch(addDoc(query, data));
        await usersIndex.save(newDoc.id, newDoc.data());
        message = 'Kayıt başarılı';
      }

      // add this user as manager to the institution
      if (data.role === 'manager') {
        await dispatch(
          updateDoc(institutionsQuery, institution, { manager: newDoc.ref })
        );
        if (doc && doc.get('institution')) {
          await dispatch(
            updateDoc(institutionsQuery, doc.get('institution').id, {
              manager: firebase.FieldValue.delete(),
            })
          );
        }
      }

      // add this user as mentor to the students
      if (selectedRole !== 'student') {
        await Promise.all(
          studentsIds.map(id => {
            if (
              students.includes(id) &&
              !(studentsById[id].get('mentors') || [])
                .map(ref => ref.id)
                .includes(newDoc.id)
            ) {
              return dispatch(
                updateDoc(studentsQuery, id, {
                  mentors: firebase.FieldValue.arrayUnion(newDoc.ref),
                })
              );
            } else if (
              !students.includes(id) &&
              (studentsById[id].get('mentors') || [])
                .map(ref => ref.id)
                .includes(newDoc.id)
            ) {
              return dispatch(
                updateDoc(studentsQuery, id, {
                  mentors: firebase.FieldValue.arrayRemove(newDoc.ref),
                })
              );
            }
            return Promise.resolve();
          })
        );
      }

      addToast(message, { appearance: 'success' });
      history.push(`${path}/${newDoc.id}`);
    } catch (error) {
      addToast(error.message, { appearance: 'error' });
      setLoading(false);
    }
  }

  if (
    institutionsIsFetching ||
    !institutions ||
    studentsIsFetching ||
    !students
  ) {
    return <Spinner />;
  } else if (institutionsFetchError) {
    return <Error error={institutionsFetchError} />;
  } else if (studentsFetchError) {
    return <Error error={studentsFetchError} />;
  }

  async function isUniqueEmail(email) {
    const exists = await firebase.getUserByEmail(email);
    return Boolean(doc) || !Boolean(exists) || 'Bu e-posta adresi kullanimda';
  }

  const imageSrc = image || watch('imageURL');
  const role = watch('role');
  const roles = getPermittedRoles(profile.role);
  return (
    <div className="flex-row-fluid ml-lg-8">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                {doc
                  ? 'Düzenle'
                  : `Yeni ${
                      selectedRole === 'student' ? 'Öğrenci' : 'Kullanıcı'
                    }`}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {doc
                  ? `${
                      selectedRole === 'student' ? 'Öğrenci' : 'Kullanıcı'
                    } 'düzenle`
                  : 'Yeni kullanıcı ekle'}
              </span>
            </div>
            <div className="card-toolbar">
              <Button
                className="btn btn-success mr-2"
                loading={loading || uploading}
              >
                {doc ? 'Kaydet' : 'Ekle'}
              </Button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history.goBack()}
              >
                İptal
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Resim
              </label>
              <div className="col-lg-9 col-xl-6">
                <div
                  className="image-input image-input-outline"
                  id="kt_profile_avatar"
                  style={{
                    backgroundImage: 'url(/media/users/blank.png)',
                  }}
                >
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      className="image-input-wrapper"
                      alt="Fotograf"
                    />
                  ) : (
                    <div className="image-input-wrapper"></div>
                  )}
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Gorseli degistir"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                    />
                    <input type="hidden" name="image" />
                  </label>
                </div>
                <span className="form-text text-muted">
                  İzin verilen dosya türleri: png, jpg, jpeg.
                </span>
                {errors.imagePath && (
                  <div className="text-danger">
                    {errors.imagePath?.type === 'fileSize'
                      ? "Resim boyutu 10MB'dan az olmalı."
                      : 'Gerekli alan'}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                E-posta
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="email"
                  className={`form-control form-control-lg form-control-solid ${errors.email &&
                    'is-invalid'}`}
                  id="email"
                  name="email"
                  ref={register({ required: true, validate: isUniqueEmail })}
                />
                <div className="invalid-feedback">
                  {errors.email?.type === 'required'
                    ? 'Gerekli alan'
                    : errors.email?.message}
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Ad
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${errors.firstName &&
                    'is-invalid'}`}
                  id="firstName"
                  name="firstName"
                  ref={register({ required: true })}
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Soyad
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${errors.lastName &&
                    'is-invalid'}`}
                  id="lastName"
                  name="lastName"
                  ref={register({ required: true })}
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div
              className={`form-group row ${errors.dateOfBirth && 'validated'}`}
            >
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Doğum tarihi
              </label>
              <div className="col-lg-9 col-xl-6">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  render={({ onChange, onBlur, value }) => (
                    <ReactDatePicker
                      className={`form-control form-control-lg form-control-solid ${errors.dateOfBirth &&
                        'is-invalid'}`}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className={`form-group row ${errors.gender && 'validated'}`}>
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Cinsiyet
              </label>
              <div className="col-lg-9 col-xl-6 col-form-label">
                <div className="radio-list">
                  {GENDERS.map((item, idx) => (
                    <label key={idx} className="radio">
                      <input
                        type="radio"
                        name="gender"
                        ref={register({ required: true })}
                        value={idx}
                      />
                      <span></span>
                      {item}
                    </label>
                  ))}
                </div>
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            {selectedRole !== 'student' && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Branş
                  <br />
                  (Çoklu seçim için ctrl +)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid ${errors.branches &&
                      'is-invalid'}`}
                    id="branches"
                    name="branches"
                    ref={register({ required: true })}
                    multiple
                  >
                    {BRANCHES.map((item, idx) => (
                      <option key={item} value={idx}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Gerekli alan</div>
                </div>
              </div>
            )}
            {selectedRole === 'student' && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Engel türü
                  <br />
                  (Çoklu seçim için ctrl +)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid ${errors.disabilities &&
                      'is-invalid'}`}
                    id="disabilities"
                    name="disabilities"
                    ref={register({ required: true })}
                    multiple
                  >
                    {DISABILITIES.map((item, idx) => (
                      <option key={item} value={idx}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Gerekli alan</div>
                </div>
              </div>
            )}
            {selectedRole !== 'student' && roles && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Rol
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`custom-select form-control form-control-lg form-control-solid ${errors.role &&
                      'is-invalid'}`}
                    id="role"
                    name="role"
                    ref={register({ required: true })}
                  >
                    {roles.map(key => (
                      <option key={key} value={key}>
                        {ROLES[key]}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Gerekli alan</div>
                </div>
              </div>
            )}
            {profile.role === 'admin' && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Kurumu
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`custom-select form-control form-control-lg form-control-solid ${errors.institution &&
                      'is-invalid'}`}
                    id="institution"
                    name="institution"
                    ref={register({ required: true })}
                  >
                    {institutions.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.get('name')}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Gerekli alan</div>
                </div>
              </div>
            )}
            {selectedRole !== 'student' && (
              <div className="form-group row">
                <label className="col-xl-3 col-lg-3 col-form-label text-right">
                  Öğrencileri
                  <br />
                  (Çoklu seçim için ctrl +)
                </label>
                <div className="col-lg-9 col-xl-6">
                  <select
                    className={`form-control form-control-lg form-control-solid ${errors.students &&
                      'is-invalid'}`}
                    id="students"
                    name="students"
                    ref={register}
                    multiple
                  >
                    {students.map(item => (
                      <option key={item.id} value={item.id}>
                        {item.get('fullname')}
                      </option>
                    ))}
                  </select>
                  <div className="invalid-feedback">Gerekli alan</div>
                </div>
              </div>
            )}
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Durum
              </label>
              <div className="col-lg-9 col-xl-6">
                <select
                  className={`custom-select form-control form-control-lg form-control-solid ${errors.status &&
                    'is-invalid'}`}
                  id="status"
                  name="status"
                  ref={register({ required: true })}
                >
                  {STATUSES.map((item, idx) => (
                    <option key={idx} value={idx}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default UserForm;
