import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../_metronic/_partials/dropdowns';

import * as ROUTES from '../../constants/routes';
import { STATUSES, STATUS_COLORS } from './constants';

function InstitutionList({ docs, onDeleteClick }) {
  const [managers, setManagers] = useState({});
  useEffect(() => {
    docs.forEach(async doc => {
      const manager = await doc.get('manager')?.get();
      setManagers(managers => ({ ...managers, [doc.id]: manager }));
    });
  }, [docs]);

  function handleDelete(event, doc) {
    event.preventDefault();
    onDeleteClick(doc);
  }

  return (
    <div className="container">
      <div className="row">
        {docs.map(doc => (
          <div key={doc.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-6 ">
            <div className="card card-custom gutter-b card-stretch">
              <div className="card-body pt-4 ">
                <div className="d-flex justify-content-end">
                  <Dropdown className="dropdown-inline" drop="down" alignRight>
                    <Dropdown.Toggle
                      as={DropdownCustomToggler}
                      id="dropdown-toggle-top"
                    >
                      <i className="ki ki-bold-more-hor" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                      <ul className="navi navi-hover py-5">
                        <li className="navi-item">
                          <Link
                            className="navi-link"
                            to={`${ROUTES.INSTITUTIONS}/${doc.id}/duzenle`}
                          >
                            <span className="navi-icon">
                              <i className="flaticon2-edit"></i>
                            </span>
                            <span className="navi-text">Düzenle</span>
                          </Link>
                        </li>
                        <li className="navi-item">
                          <a
                            href="#sil"
                            className="navi-link"
                            onClick={e => handleDelete(e, doc)}
                          >
                            <span className="navi-icon">
                              <i className="flaticon2-trash"></i>
                            </span>
                            <span className="navi-text">Sil</span>
                          </a>
                        </li>
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <div
                      className="symbol-label"
                      style={{
                        backgroundImage: `url(${doc.get('imageURL')})`,
                      }}
                    ></div>
                  </div>
                  <div>
                    <Link
                      className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                      to={`${ROUTES.INSTITUTIONS}/${doc.id}`}
                    >
                      {doc.get('name')}
                    </Link>
                    <div className="text-muted">
                      Yönetici:{' '}
                      <Link to={`${ROUTES.TEACHERS}/${managers[doc.id]?.id}`}>
                        {managers[doc.id]?.get('fullname')}
                      </Link>
                    </div>
                    <div className="mt-2">
                      <Link
                        to={`${ROUTES.INSTITUTIONS}/${doc.id}`}
                        className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                      >
                        Profilini Görüntüle
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="pt-8 pb-6">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">E-posta:</span>
                    <span className="text-muted">{doc.get('email')}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Telefon:</span>
                    <span className="text-muted">{doc.get('phone')}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Adres:</span>
                    <span className="text-muted text-right">
                      {doc.get('address')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Eğitici Sayısı:
                    </span>
                    <span className="text-muted">
                      {doc.get('numOfTrainer')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Öğrenci Sayısı:
                    </span>
                    <span className="text-muted">
                      {doc.get('numOfStudent')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Derslik Sayısı:
                    </span>
                    <span className="text-muted">
                      {doc.get('numOfClassroom')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="font-weight-bold mr-2">Onay Durumu:</span>
                    <span>
                      <span
                        className={`badge badge-${
                          STATUS_COLORS[doc.get('status')]
                        } mb-1 mr-1`}
                      >
                        {STATUSES[doc.get('status')]}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default InstitutionList;
