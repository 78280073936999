import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

import * as ROUTES from '../../constants/routes';
import { fetchDocs, updateDoc } from '../../actions';
import Spinner from '../Spinner';
import Error from '../Error';
import { gamesPath, getGames } from '../Games';
import { SKILLS } from '../Games/constants';
import COLORS from '../../constants/colors';
import { useFirebase } from '../../hooks';
import { usersPath } from '.';
import { getAuthUser, getProfile } from '../../reducers';
import { scratchURL } from '../../config';

function UserGames({ user, onGamesFetched, sessions }) {
  const authUser = useSelector(getAuthUser);
  const profile = useSelector(getProfile);
  const role = profile.role;
  const dispatch = useDispatch();
  const query = useMemo(
    () => ({
      path: gamesPath,
    }),
    []
  );
  useEffect(() => {
    dispatch(fetchDocs(query));
  }, [dispatch, query]);

  const gamesSelector = useCallback(state => getGames(query)(state), [query]);
  const { docs: games, byId: gamesById, isFetching, fetchError } = useSelector(
    gamesSelector
  );

  const firebase = useFirebase();
  const { addToast } = useToasts();
  const [assignedGames, setAssignedGames] = useState([]);
  useEffect(() => {
    setAssignedGames((user.get('assignedGames') || []).map(ref => ref.id));
  }, [user]);
  const [loading, setLoading] = useState(false);
  async function handleSwitchChange(gameId, value) {
    try {
      setLoading(true);
      const gameRef = gamesById[gameId].ref;
      const userData = {
        assignedGames: value
          ? firebase.FieldValue.arrayUnion(gameRef)
          : firebase.FieldValue.arrayRemove(gameRef),
      };
      const gameData = {
        assignedUsers: value
          ? firebase.FieldValue.arrayUnion(user.ref)
          : firebase.FieldValue.arrayRemove(user.ref),
      };
      await Promise.all([
        dispatch(updateDoc({ path: usersPath }, user.id, userData)),
        dispatch(updateDoc(query, gameId, gameData)),
      ]);

      setAssignedGames(assignedGames =>
        value
          ? [...assignedGames, gameId]
          : assignedGames.filter(id => id !== gameId)
      );
      addToast('Değişiklikler kaydedildi', { appearance: 'success' });
      setLoading(false);
    } catch (error) {
      addToast(error.message, { appearance: 'error' });
      setLoading(false);
    }
  }

  const isStudent = role === 'student';
  const isTeacher = role === 'teacher' || role === 'specialist';
  const [filteredGames, setFilteredGames] = useState([]);
  useEffect(() => {
    if (games) {
      let fGames = games;
      if (isStudent) {
        fGames = assignedGames.map(id => gamesById[id]);
      } else if (isTeacher && user.get('role') !== 'student') {
        fGames = games.filter(
          game => game.get('createdBy').id === authUser.uid
        );
      }
      setFilteredGames(fGames);
      onGamesFetched(fGames.length);
    }
  }, [
    assignedGames,
    authUser.uid,
    games,
    gamesById,
    isStudent,
    isTeacher,
    user,
    onGamesFetched,
  ]);

  const [numPlays, setNumPlays] = useState({});
  useEffect(() => {
    const nPlays = {};
    sessions.forEach(session => {
      const gameId = session.get('game').id;
      nPlays[gameId] = (nPlays[gameId] || 0) + 1;
    });
    setNumPlays(nPlays);
  }, [sessions]);

  if (isFetching || (!games && !fetchError)) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <div className="card card-custom card-stretch gutter-b">
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            {user.get('role') === 'student' ? 'Oyunlar' : 'Oyun Kütüphanesi'}
          </span>
          {user.get('role') === 'student' && (
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              Bu öğrencinin kendi kütüphanesinde hangi oyunları görüntülemesini
              ve oynamasını istiyorsanız seçin
            </span>
          )}
        </h3>
        <div className="card-toolbar">{loading && <Spinner />}</div>
      </div>
      <div className="card-body pt-3 pb-0">
        <div className="table-responsive">
          <table className="table table-borderless table-vertical-center">
            <thead>
              <tr>
                <th className="p-0" style={{ width: '50px' }}></th>
                <th className="p-0" style={{ minWidth: '200px' }}></th>
                <th className="p-0" style={{ minWidth: '100px' }}></th>
                <th className="p-0" style={{ minWidth: '125px' }}></th>
                {!isStudent && (
                  <>
                    <th className="p-0" style={{ minWidth: '110px' }}></th>
                    <th className="p-0" style={{ minWidth: '150px' }}></th>
                  </>
                )}
                {isStudent && (
                  <th className="p-0" style={{ minWidth: '150px' }}></th>
                )}
              </tr>
            </thead>
            <tbody>
              {filteredGames.map((game, idx) => (
                <tr key={game.id}>
                  <td className="pl-0 py-4">
                    <div className="symbol symbol-100 symbol-light mr-1">
                      <span
                        className="symbol-label"
                        style={{ overflow: 'hidden' }}
                      >
                        <img
                          src={game.get('imageURL')}
                          className="align-self-center"
                          alt={game.get('name')}
                          style={{
                            objectFit: 'cover',
                            width: '100px',
                            height: '100px',
                          }}
                        />
                      </span>
                    </div>
                  </td>
                  <td className="pl-0">
                    <Link
                      className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      to={`${ROUTES.GAMES}/${game.id}`}
                    >
                      {game.get('name')}
                    </Link>
                    <div>
                      <span className="font-weight-bolder">by</span>{' '}
                      <Link
                        className="text-muted font-weight-bold text-hover-primary"
                        to={`${ROUTES.TEACHERS}/${game.get('createdBy').id}`}
                      >
                        {game.get('creatorFullname')}
                      </Link>
                    </div>
                  </td>
                  <td className="text-right">
                    <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                      {numPlays[game.id]}
                    </span>
                    <span className="text-muted font-weight-bold">
                      kez oynandı
                    </span>
                  </td>
                  <td className="text-right">
                    <span className="text-muted font-weight-500">
                      {(game.get('skills') || []).map(skill => (
                        <span
                          key={skill}
                          className={`badge badge-${COLORS[skill]} mb-1 mr-1`}
                        >
                          {SKILLS[skill]}
                        </span>
                      ))}
                    </span>
                  </td>
                  {!isStudent && (
                    <>
                      <td className="text-right">
                        {assignedGames.includes(game.id) && (
                          <span className="label label-lg label-light-success label-inline">
                            Atandı
                          </span>
                        )}
                      </td>
                      <td className="text-right pr-0">
                        <span className="switch switch-outline switch-icon switch-primary">
                          <label>
                            <input
                              type="checkbox"
                              checked={assignedGames.includes(game.id)}
                              name="select"
                              onChange={e =>
                                handleSwitchChange(
                                  game.id,
                                  !assignedGames.includes(game.id)
                                )
                              }
                            />
                            <span></span>
                          </label>
                        </span>
                      </td>
                    </>
                  )}
                  {isStudent && (
                    <td className="text-right">
                      <a
                        href={`${scratchURL}?userId=${authUser.uid}&gameId=${game.id}`}
                        target="__blank"
                        className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                      >
                        Oyna
                      </a>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default UserGames;
