import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Dashboard from '../components/Dashboard';
import { getProfile, getAuthUser } from '../reducers';

export function DashboardPage() {
  const authUser = useSelector(getAuthUser);
  const profile = useSelector(getProfile);
  if (profile.role === 'student') {
    return <Redirect to={`ogrenciler/${authUser.uid}`} />;
  }
  return <Dashboard />;
}
