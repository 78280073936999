import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';

import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';
import { fetchDocs } from '../../../actions';
import Spinner from '../../Spinner';
import Error from '../../Error';
import { gamesPath, getGames } from '../../Games';
import { SKILLS } from '../../Games/constants';
import COLORS from '../../../constants/colors';
import { getSessions, sessionsPath } from '../../Users/GameCharts';

export function GamesTable({ className }) {
  const dispatch = useDispatch();
  const query = useMemo(
    () => ({
      path: gamesPath,
    }),
    []
  );
  useEffect(() => {
    dispatch(fetchDocs(query));
  }, [dispatch, query]);

  const gamesSelector = useCallback(state => getGames(query)(state), [query]);
  const { docs: games, isFetching, fetchError } = useSelector(gamesSelector);

  const sessionsQuery = useMemo(
    () => ({
      path: sessionsPath,
    }),
    []
  );
  useEffect(() => {
    dispatch(fetchDocs(sessionsQuery));
  }, [dispatch, sessionsQuery]);

  const sessionsSelector = useCallback(
    state => getSessions(sessionsQuery)(state),
    [sessionsQuery]
  );
  const { docs: sessions } = useSelector(sessionsSelector);

  const [numPlays, setNumPlays] = useState({});
  useEffect(() => {
    const nPlays = {};
    (sessions || []).forEach(session => {
      const gameId = session.get('game').id;
      nPlays[gameId] = (nPlays[gameId] || 0) + 1;
    });
    setNumPlays(nPlays);
  }, [sessions]);

  if (isFetching || (!games && !fetchError)) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Oyunlar
          </span>
          <span className="text-muted mt-3 font-weight-bold font-size-sm">
            Sistemde yer alan tum oyunlar
          </span>
        </h3>
      </div>
      {/* Body */}
      <div className="card-body pt-0 pb-3">
        <div className="tab-content">
          <div className="table-responsive">
            <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
              <thead>
                <tr className="text-left text-uppercase">
                  <th className="pl-7" style={{ minWidth: '250px' }}>
                    <span className="text-dark-75">oyun adi</span>
                  </th>
                  <th style={{ minWidth: '150px' }}>oynanma sayisi</th>
                  <th style={{ minWidth: '100px' }}>beceleriler</th>
                  <th style={{ minWidth: '130px' }}>begeni</th>
                </tr>
              </thead>
              <tbody>
                {games.map((game, idx) => (
                  <tr key={'game' + idx}>
                    <td className="pl-0 py-8">
                      <div className="d-flex align-items-center">
                        <div className="symbol symbol-50 symbol-light mr-4">
                          <span className="symbol-label">
                            <span className="svg-icon align-self-end">
                              <img
                                src={game.get('imageURL')}
                                alt={game.get('name')}
                                style={{
                                  objectFit: 'cover',
                                  width: '50px',
                                  height: '50px',
                                }}
                              />
                            </span>
                          </span>
                        </div>
                        <div>
                          <Link
                            to={`${ROUTES.GAMES}/${game.id}`}
                            className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                          >
                            {game.get('name')}
                          </Link>
                          <span className="text-muted font-weight-bold d-block">
                            <span className="font-weight-bolder">by</span>{' '}
                            <Link
                              className="text-muted font-weight-bold text-hover-primary"
                              to={`${ROUTES.TEACHERS}/${
                                game.get('createdBy').id
                              }`}
                            >
                              {game.get('creatorFullname')}
                            </Link>
                          </span>
                        </div>
                      </div>
                    </td>
                    <td>
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {numPlays[game.id] || 0}
                      </span>
                      <span className="text-muted font-weight-bold">
                        kez oynandı
                      </span>
                    </td>
                    <td>
                      <span className="text-muted font-weight-500">
                        {(game.get('skills') || []).map(skill => (
                          <span
                            key={skill}
                            className={`badge badge-${COLORS[skill]} mb-1 mr-1`}
                          >
                            {SKILLS[skill]}
                          </span>
                        ))}
                      </span>
                    </td>
                    <td>
                      <img
                        src={toAbsoluteUrl('/media/logos/stars.png')}
                        alt="image"
                        style={{ width: '5.5rem' }}
                      />
                      <span className="text-muted font-weight-bold d-block font-size-sm">
                        Cok iyi
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
