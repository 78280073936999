import algoliasearch from 'algoliasearch';

import { algoliaConfig } from '../config';
import { decycle } from './decycle';

export default class AlgoliaAPI {
  constructor(indexName) {
    this.client = algoliasearch(algoliaConfig.appId, algoliaConfig.searchKey);
    this.index = this.client.initIndex(indexName);
  }

  save = async (id, object) => {
    object = decycle(object);
    return this.index.saveObject({ ...object, objectID: id });
  };

  update = async (id, object) => {
    object = decycle(object);
    return this.index.partialUpdateObject({ ...object, objectID: id });
  };

  delete = id => this.index.deleteObject(id);

  search = (query, params) => this.index.search(query, params);
}
