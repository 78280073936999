import { combineReducers } from 'redux';

import authUser, * as fromAuthUser from './authUser';
import profile, * as fromProfile from './profile';

export default combineReducers({ authUser, profile });

export const getAuthUser = state => fromAuthUser.getAuthUser(state.authUser);
export const getSignInError = state =>
  fromAuthUser.getSignInError(state.authUser);
export const getSignInFetching = state =>
  fromAuthUser.getSignInFetching(state.authUser);
export const getSignUpError = state =>
  fromAuthUser.getSignUpError(state.authUser);
export const getSignUpFetching = state =>
  fromAuthUser.getSignUpFetching(state.authUser);

export const getProfile = state => fromProfile.getProfile(state.profile);
export const getProfileIsFetching = state =>
  fromProfile.getProfileIsFetching(state.profile);
