export const SKILLS = [
  'Motor beceri',
  'El göz koordinasyonu',
  'Günlük yaşam becerileri',
  'Öz bakım becerileri',
  'Dil ve iletişim becerileri',
  'Toplumsal uyum becerileri',
  'Dikkat becerileri',
  'Matematik',
  'Rakam ve kavram öğretimi',
  'Hayat bilgisi',
  'Taklit becerileri',
  'Eşleme becerileri',
  'Türkçe',
  'Okuma yazma',
];

export const STATUSES = ['Beklemede', 'Onaylandı', 'Reddedildi'];
