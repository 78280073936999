export const ROLES = {
  admin: 'Admin',
  manager: 'Kurum Yöneticisi',
  specialist: 'Uzman',
  teacher: 'Öğretmen',
  student: 'Öğrenci',
};

export const BRANCHES = [
  'Özel Eğitim',
  'Okul Öncesi',
  'Sınıf Öğretmeni',
  'Fizyoterapi',
];

export const DISABILITIES = [
  'Zihinsel Yetersizlik',
  'Motor Beceri Eksikliği',
  'Yaygın Gelişimsel Bozukluk',
  'Özel Öğrenme Güçlüğü',
  'İşitme Yetersizliği',
];

export const STATUSES = ['Beklemede', 'Onaylandı', 'Reddedildi'];

export const STATUS_COLORS = ['info', 'success', 'danger'];

export const GENDERS = ['Kız', 'Erkek'];
