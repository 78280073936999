import React, { useState } from 'react';

import Cookies from 'js-cookie';

const cookieName = 'cookie-use';

export default function Cookie() {
  const [isAccepted, setIsAccepted] = useState(Cookies.get(cookieName));

  function handleClose() {
    Cookies.set(cookieName, true, { expires: 365 * 10 });
    setIsAccepted(true);
  }

  if (isAccepted) {
    return null;
  }

  return (
    <div className="cookie-notification fixed-bottom bg-light">
      <div className="container p-2">
        <div className="row">
          <div className="col text-center">
            hareketlen.com'un temel işlevleri ve analizleri için çerezleri
            kullanıyoruz. hareketlen.com'u kullanmaya devam ederek çerez
            politikamızı kabul etmiş olursunuz.
          </div>
          <div className="col-auto d-flex justify-content-center align-items-center">
            <button className="btn p-0" onClick={handleClose}>
              x
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
