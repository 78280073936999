import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, Redirect } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { useFormInput, useSignInAuthorization } from '../hooks';
import { signIn, signInWithProvider } from '../actions';
import { getSignInError, getSignInFetching } from '../reducers';

import '../_metronic/_assets/sass/pages/login/login-3.scss';

function useRedirectUrl() {
  const location = useLocation();
  const query = location.search.split('=');
  if (query.length === 2 && query[0] === '?redirect') {
    return query[1];
  }
  return ROUTES.HOME;
}

function SignInSocial({ provider, children }) {
  const dispatch = useDispatch();
  async function handleClick(event) {
    event.preventDefault();
    await dispatch(signInWithProvider(provider));
  }

  const isSignedIn = useSignInAuthorization(false);
  const redirectUrl = useRedirectUrl();
  if (isSignedIn) {
    return <Redirect to={redirectUrl} />;
  }

  return (
    <button
      type="button"
      className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
      onClick={handleClick}
    >
      <span className="svg-icon svg-icon-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M19.9895 10.1871C19.9895 9.36767 19.9214 8.76973 19.7742 8.14966H10.1992V11.848H15.8195C15.7062 12.7671 15.0943 14.1512 13.7346 15.0813L13.7155 15.2051L16.7429 17.4969L16.9527 17.5174C18.879 15.7789 19.9895 13.221 19.9895 10.1871Z"
            fill="#4285F4"
          />
          <path
            d="M10.1993 19.9313C12.9527 19.9313 15.2643 19.0454 16.9527 17.5174L13.7346 15.0813C12.8734 15.6682 11.7176 16.0779 10.1993 16.0779C7.50243 16.0779 5.21352 14.3395 4.39759 11.9366L4.27799 11.9466L1.13003 14.3273L1.08887 14.4391C2.76588 17.6945 6.21061 19.9313 10.1993 19.9313Z"
            fill="#34A853"
          />
          <path
            d="M4.39748 11.9366C4.18219 11.3166 4.05759 10.6521 4.05759 9.96565C4.05759 9.27909 4.18219 8.61473 4.38615 7.99466L4.38045 7.8626L1.19304 5.44366L1.08875 5.49214C0.397576 6.84305 0.000976562 8.36008 0.000976562 9.96565C0.000976562 11.5712 0.397576 13.0882 1.08875 14.4391L4.39748 11.9366Z"
            fill="#FBBC05"
          />
          <path
            d="M10.1993 3.85336C12.1142 3.85336 13.406 4.66168 14.1425 5.33717L17.0207 2.59107C15.253 0.985496 12.9527 0 10.1993 0C6.2106 0 2.76588 2.23672 1.08887 5.49214L4.38626 7.99466C5.21352 5.59183 7.50242 3.85336 10.1993 3.85336Z"
            fill="#EB4335"
          />
        </svg>
      </span>
      {children}
    </button>
  );
}

export function SocialSignInButtons() {
  return <SignInSocial provider="google">Google Hesabıyla Giriş</SignInSocial>;
}

export default function SignIn() {
  const email = useFormInput('');
  const password = useFormInput('');

  const error = useSelector(getSignInError);
  const errorMessage =
    error &&
    (error.code === 'auth/too-many-requests'
      ? 'Çok sayıda başarısız giriş denemesi. Lütfen daha sonra tekrar deneyiniz...'
      : error.message);
  const isFetching = useSelector(getSignInFetching);

  const dispatch = useDispatch();
  function handleSubmit(event) {
    event.preventDefault();
    dispatch(signIn(email.value, password.value));
  }

  const isSignedIn = useSignInAuthorization(false);
  const redirectUrl = useRedirectUrl();
  if (isSignedIn) {
    return <Redirect to={redirectUrl} />;
  }

  const isInvalid = password.value === '' || email.value === '';

  return (
    <div className="d-flex flex-column flex-root">
      <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="login-aside d-flex flex-column flex-row-auto">
          <div className="d-flex flex-column-auto flex-column pt-lg-5 pt-15">
            <a href="/" className="login-logo text-center pt-lg-25 pb-10">
              <img
                src="/media/logos/logo-1-hareketlen.png"
                className="max-h-70px"
                alt=""
              />
            </a>
            <h3 className="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
              Hareketli Oyun &amp; Etkinlikler
              <br />
              Eğlenceli Öğrenme ve Sistemli Takip
            </h3>
          </div>
          <div
            className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center"
            style={{
              backgroundPositionY: 'calc(100% + 5rem)',
              backgroundImage:
                'url(/media/svg/illustrations/hareketlen-login-visual-5.png)',
            }}
          ></div>
        </div>
        <div className="login-content flex-row-fluid d-flex flex-column p-10">
          <div className="d-flex flex-row-fluid flex-center">
            <div className="login-form">
              <form
                id="kt_login_singin_form"
                className="form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                    Giriş Yapın
                  </h3>
                  <div className="text-muted font-weight-bold font-size-h4">
                    Hesabınız yok mu?{' '}
                    <Link
                      to={ROUTES.SIGNUP}
                      className="text-primary font-weight-bolder"
                    >
                      Kaydolun
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">
                    E-posta
                  </label>
                  <input
                    type="email"
                    placeholder="Sisteme kayıtlı E-posta adresiniz"
                    required
                    autoFocus
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="email"
                    className="form-control h-auto py-7 px-6 rounded-lg border-0"
                    {...email}
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Şifre
                    </label>
                    <Link
                      className="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                      to={ROUTES.PASSWORD_FORGET}
                    >
                      Şifremi unuttum
                    </Link>
                  </div>
                  <input
                    type="password"
                    placeholder="Şifreniz"
                    required
                    name="password"
                    {...password}
                    className="form-control h-auto py-7 px-6 rounded-lg border-0"
                    autoComplete="off"
                  />
                </div>
                <div className="pb-lg-0 pb-5">
                  <button
                    disabled={isInvalid}
                    type="submit"
                    id="kt_login_singin_form_submit_button"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                  >
                    {isFetching ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Yükleniyor...</span>
                      </>
                    ) : (
                      'Giriş'
                    )}
                  </button>
                  {!isFetching && error && (
                    <p className="mt-2 text-danger">{errorMessage}</p>
                  )}
                  <SocialSignInButtons />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
