import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { SocialSignInButtons } from './SignIn';
import * as ROUTES from '../constants/routes';
import { useFormInput, useSignInAuthorization } from '../hooks';
import { signUp } from '../actions';
import { getSignUpError, getSignUpFetching } from '../reducers';

import '../_metronic/_assets/sass/pages/login/login-3.scss';

export default function SignUp() {
  const email = useFormInput('');
  const passwordOne = useFormInput('');
  const passwordTwo = useFormInput('');

  const error = useSelector(getSignUpError);
  const isFetching = useSelector(getSignUpFetching);

  const dispatch = useDispatch();
  async function handleSubmit(event) {
    event.preventDefault();
    const user = {
      email: email.value,
      password: passwordOne.value,
    };
    try {
      await dispatch(signUp(user));
    } catch (error) {
      // nothing todo
    }
  }

  const isSignedIn = useSignInAuthorization(false);
  if (isSignedIn) {
    return <Redirect to={ROUTES.HOME} />;
  }

  const isInvalid =
    passwordOne.value !== passwordTwo.value ||
    passwordOne.value === '' ||
    email.value === '';

  return (
    <div className="d-flex flex-column flex-root">
      <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="login-aside d-flex flex-column flex-row-auto">
          <div className="d-flex flex-column-auto flex-column pt-lg-5 pt-15">
            <a href="/" className="login-logo text-center pt-lg-25 pb-10">
              <img
                src="/media/logos/logo-1-hareketlen.png"
                className="max-h-70px"
                alt=""
              />
            </a>
            <h3 className="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
              Hareketli Oyun &amp; Etkinlikler
              <br />
              Eğlenceli Öğrenme ve Sistemli Takip
            </h3>
          </div>
          <div
            className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center"
            style={{
              backgroundPositionY: 'calc(100% + 5rem)',
              backgroundImage:
                'url(/media/svg/illustrations/hareketlen-login-visual-5.png)',
            }}
          ></div>
        </div>
        <div className="login-content flex-row-fluid d-flex flex-column p-10">
          <div className="d-flex flex-row-fluid flex-center">
            <div className="login-form">
              <form
                id="kt_login_singin_form"
                className="form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                    Kaydol
                  </h3>
                  <div className="text-muted font-weight-bold font-size-h4">
                    Zaten kayıtlı mısınız?{' '}
                    <Link
                      to={ROUTES.SIGNIN}
                      className="text-primary font-weight-bolder"
                    >
                      Giriş yapın
                    </Link>
                  </div>
                </div>
                <div className="form-group">
                  <label className="font-size-h6 font-weight-bolder text-dark">
                    E-posta
                  </label>
                  <input
                    type="email"
                    placeholder="E-posta adresiniz"
                    required
                    autoFocus
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="email"
                    className="form-control h-auto py-7 px-6 rounded-lg border-0"
                    {...email}
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Şifre
                    </label>
                  </div>
                  <input
                    type="password"
                    placeholder="Şifreniz"
                    required
                    name="passwordOne"
                    {...passwordOne}
                    className="form-control h-auto py-7 px-6 rounded-lg border-0"
                    autoComplete="off"
                  />
                </div>
                <div className="form-group">
                  <div className="d-flex justify-content-between mt-n5">
                    <label className="font-size-h6 font-weight-bolder text-dark pt-5">
                      Şifre tekrar
                    </label>
                  </div>
                  <input
                    type="password"
                    placeholder="Şifrenizi tekrar girin"
                    required
                    name="passwordTwo"
                    {...passwordTwo}
                    className="form-control h-auto py-7 px-6 rounded-lg border-0"
                    autoComplete="off"
                  />
                </div>
                <div className="pb-lg-0 pb-5">
                  <button
                    disabled={isInvalid}
                    type="submit"
                    id="kt_login_singin_form_submit_button"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3"
                  >
                    {isFetching ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Yükleniyor...</span>
                      </>
                    ) : (
                      'Kaydol'
                    )}
                  </button>
                  {!isFetching && error && (
                    <p className="mt-2 text-danger">{error.message}</p>
                  )}
                  <SocialSignInButtons />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
