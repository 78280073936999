/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from 'react';
import SVG from 'react-inlinesvg';
import { useHistory, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toAbsoluteUrl } from '../../../../_helpers';

import * as ROUTES from '../../../../../constants/routes';
import { getAuthUser, getProfile } from '../../../../../reducers';
import { ROLES } from '../../../../../components/Users/constants';

export function QuickUser() {
  const history = useHistory();
  const logoutClick = () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    history.push(ROUTES.SIGNOUT);
  };

  const profile = useSelector(getProfile);
  const authUser = useSelector(getAuthUser);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">Kullanıcı Profili</h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(profile.imageURL)})`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div>
          <div className="d-flex flex-column">
            <Link
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
              to={`${
                profile.role === 'student' ? ROUTES.STUDENTS : ROUTES.TEACHERS
              }/${authUser.uid}`}
            >
              {profile.fullname}
            </Link>
            <div className="text-muted mt-1">{ROLES[profile.role]}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          '/media/svg/icons/Communication/Mail-notification.svg'
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {profile.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Çıkış
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div>
          <h5 className="mb-5">Son Bilgilendirmeler</h5>

          <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
            <span className="svg-icon svg-icon-warning mr-5">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Home/Library.svg')}
                className="svg-icon svg-icon-lg"
              ></SVG>
            </span>

            <div className="d-flex flex-column flex-grow-1 mr-2">
              <a
                href="#"
                className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
              >
                Yeni oyun atandı
              </a>
              <span className="text-muted font-size-sm">Due in 2 Days</span>
            </div>

            <span className="font-weight-bolder text-warning py-1 font-size-lg">
              +28%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
