import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { LayoutSplashScreen, Layout } from './_metronic/layout';
import BasePage from './BasePage';
import ErrorPage from './components/ErrorPage';

import {
  useAuthentication,
  useScrollTop,
  useSignInAuthorization,
} from './hooks';
import Cookie from './components/Cookie';
import * as ROUTES from './constants/routes';

import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import SignOut from './pages/SignOut';
import PasswordForget from './pages/PasswordForget';

function ScrollTop() {
  useScrollTop();
  return null;
}

export function Routes() {
  const loading = useAuthentication();

  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    const promises = [];
    // fetch things required to run app
    Promise.all(promises).then(() => {
      setLoadingData(false);
    });
  }, [dispatch]);

  const isSignedIn = useSignInAuthorization(false);

  if (loading || loadingData) {
    return <LayoutSplashScreen />;
  }

  return (
    <>
      <ScrollTop />
      <Cookie />
      <Switch>
        <Route path="/error" component={ErrorPage} />
        <Route path={ROUTES.SIGNUP} component={SignUp} />
        <Route path={ROUTES.SIGNIN} component={SignIn} />
        <Route path={ROUTES.SIGNOUT} component={SignOut} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
        {isSignedIn ? (
          <Layout>
            <BasePage />
          </Layout>
        ) : (
          <Redirect to={ROUTES.SIGNIN} />
        )}
      </Switch>
    </>
  );
}
