import React from 'react';

import Dialog from './Dialog';

export default function DeleteDialog({ isOpen, onDelete, onToggle, itemName }) {
  const buttons = [
    <button key="close" className="btn" onClick={onToggle}>
      İptal
    </button>,
    <button
      key="delete"
      className="btn btn-danger"
      onClick={async () => {
        await onDelete();
        onToggle();
      }}
    >
      Sil
    </button>,
  ];

  return (
    <Dialog title="Sil" buttons={buttons} isOpen={isOpen} onToggle={onToggle}>
      <em>{itemName}</em> silmek istediğinizden emin misiniz?
    </Dialog>
  );
}
