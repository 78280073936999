import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { useHistory, Link } from 'react-router-dom';

import * as ROUTES from '../constants/routes';
import { useFormInput } from '../hooks';
import { forgetPassword } from '../actions';

export default function PasswordForget() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const email = useFormInput('');
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  function handleSubmit(event) {
    setIsFetching(true);
    dispatch(forgetPassword(email.value))
      .then(() => {
        setIsFetching(false);
        addToast('A password reset e-mail was send.', {
          appearance: 'success',
          autoDismiss: true,
        });
        history.push(ROUTES.HOME);
      })
      .catch(error => {
        setIsFetching(false);
        setError(error);
      });
    event.preventDefault();
  }

  const isInvalid = email.value === '';

  return (
    <div className="d-flex flex-column flex-root">
      <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="login-aside d-flex flex-column flex-row-auto">
          <div className="d-flex flex-column-auto flex-column pt-lg-5 pt-15">
            <a href="/" className="login-logo text-center pt-lg-25 pb-10">
              <img
                src="/media/logos/logo-1-hareketlen.png"
                className="max-h-70px"
                alt=""
              />
            </a>
            <h3 className="font-weight-bolder text-center font-size-h4 text-dark-50 line-height-xl">
              Hareketli Oyun &amp; Etkinlikler
              <br />
              Eğlenceli Öğrenme ve Sistemli Takip
            </h3>
          </div>
          <div
            className="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-x-center"
            style={{
              backgroundPositionY: 'calc(100% + 5rem)',
              backgroundImage:
                'url(/media/svg/illustrations/hareketlen-login-visual-5.png)',
            }}
          ></div>
        </div>
        <div className="login-content flex-row-fluid d-flex flex-column p-10">
          <div className="d-flex flex-row-fluid flex-center">
            <div className="login-form">
              <form
                className="form"
                id="kt_login_forgot_form"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="pb-5 pb-lg-15">
                  <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
                    Şifrenizi mi unuttunuz ?
                  </h3>
                  <p className="text-muted font-weight-bold font-size-h4">
                    Şifrenizi sıfırlamak için e-posta adresinizi giriniz
                  </p>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    placeholder="Sisteme kayıtlı e-posta adresiniz"
                    required
                    autoFocus
                    autoCapitalize="off"
                    autoCorrect="off"
                    name="email"
                    {...email}
                    className="form-control h-auto py-7 px-6 border-0 rounded-lg font-size-h6"
                  />
                </div>
                <div className="form-group d-flex flex-wrap">
                  <button
                    disabled={isInvalid}
                    type="submit"
                    id="kt_login_forgot_form_submit_button"
                    className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  >
                    {isFetching ? (
                      <>
                        <span
                          className="spinner-grow spinner-grow-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="sr-only">Yükleniyor...</span>
                      </>
                    ) : (
                      'Şifreyi sıfırla'
                    )}
                  </button>
                  <Link
                    to={ROUTES.SIGNIN}
                    id="kt_login_forgot_cancel"
                    className="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  >
                    İptal
                  </Link>
                </div>
                {!isFetching && error && (
                  <p className="mt-2 text-danger">{error.message}</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
