import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

import { firebaseConfig } from '../config';

export default class FirebaseAPI {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.FieldValue = app.firestore.FieldValue;
    this.Timestamp = app.firestore.Timestamp;

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.functions();

    this.emailAuthProvider = app.auth.EmailAuthProvider;
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();
  }

  creating = data => ({
    ...data,
    createdAt: this.FieldValue.serverTimestamp(),
    createdBy: this.user(this.auth.currentUser.uid),
  });

  updating = data => ({
    ...data,
    updatedAt: this.FieldValue.serverTimestamp(),
    updatedBy: this.user(this.auth.currentUser.uid),
  });

  user = uid => this.db.doc(`users/${uid}`);

  users = () => this.db.collection('users');

  signUp = async data => {
    const { email, password, ...rest } = data;
    const doc = await this.getUserByEmail(email);
    if (!doc) {
      throw new Error(
        'Sisteme kaydiniz bulunmamaktadir. Kurum yoneticinizle irtibata geciniz.'
      );
    }
    const authUser = await this.auth.createUserWithEmailAndPassword(
      email,
      password
    );
    const user = doc.data();
    const newDoc = this.user(authUser.user.uid);
    await newDoc.set(
      this.updating({
        ...rest,
        ...user,
      }),
      { merge: true }
    );

    const institutions = await this.fetchDocs('institutions', {
      where: [['manager', '==', doc.ref]],
    });
    await Promise.all(institutions.map(i => i.ref.update({ manager: newDoc })));

    const students = await this.fetchDocs('users', {
      where: [['mentors', 'array-contains', doc.ref]],
    });
    await Promise.all(
      students.map(s => {
        const mentors = s.get('mentors').filter(m => m.id !== doc.id);
        mentors.push(newDoc);
        return s.ref.update({ mentors });
      })
    );

    const mentors = await this.fetchDocs('users', {
      where: [['students', 'array-contains', doc.ref]],
    });
    await Promise.all(
      mentors.map(m => {
        const students = m.get('students').filter(s => s.id !== doc.id);
        students.push(newDoc);
        return m.ref.update({ students });
      })
    );

    const games = await this.fetchDocs('games', {
      where: [['assignedUsers', 'array-contains', doc.ref]],
    });
    await Promise.all(
      games.map(g => {
        const assignedUsers = g
          .get('assignedUsers')
          .filter(u => u.id !== doc.id);
        assignedUsers.push(newDoc);
        return g.ref.update({ assignedUsers });
      })
    );

    await this.deleteDoc('/users', doc.id);
    await this.sendEmailVerification();
    return authUser;
  };

  signInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  signInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  signInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  signInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  signOut = () => this.auth.signOut();

  sendPasswordResetEmail = email => this.auth.sendPasswordResetEmail(email);

  sendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_EMAIL_VERIFICATION_REDIRECT,
    });

  updatePassword = password => this.auth.currentUser.updatePassword(password);

  deleteAccount = async () => {
    await this.auth.currentUser.delete();
  };

  onAuthStateChanged = listener =>
    this.auth.onAuthStateChanged(authUser => {
      if (!authUser) {
        listener(authUser, {});
        return;
      }
      this.getUser(authUser.uid).then(user => {
        listener(authUser, user);
      });
    });

  getUser = async uid => {
    const user = await this.user(uid).get();
    return user.data();
  };

  getUserByEmail = async email => {
    const q = this.db.collection('/users');
    const docs = await this.query(q, { where: [['email', '==', email]] });
    if (!docs.length) {
      return null;
    }
    return docs[0];
  };

  updateUser = async data => {
    const currentUser = this.auth.currentUser;
    await this.user(currentUser.uid).update(this.updating(data));
  };

  query = (q, params = {}) => {
    const { where, limit, orderBy, startAfter } = params;
    if (where) {
      where.forEach(w => {
        q = q.where(...w);
      });
    }
    if (limit) {
      q = q.limit(limit);
    }
    if (orderBy) {
      if (Array.isArray(orderBy)) {
        q = q.orderBy(...orderBy);
      } else {
        q = q.orderBy(orderBy);
      }
    }
    if (startAfter) {
      q = q.startAfter(startAfter);
    }
    return q.get().then(snapshot => snapshot.docs);
  };

  fetchDocs = (path, params = {}) => {
    if (!params.orderBy) {
      params.orderBy = ['createdAt', 'desc'];
    }
    const q = this.db.collection(path);
    return this.query(q, params);
  };

  fetchDoc = (path, id) => this.db.doc(`${path}/${id}`).get();

  addDoc = async (path, data) => {
    const colRef = this.db.collection(path);
    const docRef = await colRef.add(this.creating(data));
    return docRef.get();
  };

  updateDoc = async (path, id, data) => {
    const docRef = this.db.doc(`${path}/${id}`);
    await docRef.update(this.updating(data));
    return docRef.get();
  };

  deleteDoc = async (path, id) => {
    const docRef = this.db.doc(`${path}/${id}`);
    await docRef.delete();
  };
}
