import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../_metronic/_partials/dropdowns';
import moment from 'moment';
import 'moment/locale/tr';

import {
  ROLES,
  GENDERS,
  BRANCHES,
  STATUSES,
  STATUS_COLORS,
  DISABILITIES,
} from './constants';
import COLORS from '../../constants/colors';
import { getProfile } from '../../reducers';

moment.locale('tr');

function UserList({ docs, onDeleteClick, selectedRole, path }) {
  const profile = useSelector(getProfile);
  const role = profile.role;
  const [institutions, setInstitutions] = useState([]);
  useEffect(() => {
    let cancel = false;
    Promise.all(
      docs.map(async doc => {
        return await doc.get('institution')?.get();
      })
    ).then(institutions => {
      if (!cancel) {
        setInstitutions(institutions);
      }
    });
    return () => {
      cancel = true;
    };
  }, [docs]);

  function handleDelete(event, doc) {
    event.preventDefault();
    onDeleteClick(doc);
  }

  return (
    <div className="container">
      <div className="row">
        {docs.map((doc, idx) => (
          <div key={doc.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
            <div className="card card-custom gutter-b card-stretch">
              <div className="card-body pt-4">
                <div className="d-flex justify-content-end">
                  {(role === 'admin' || role === 'manager') && (
                    <Dropdown
                      className="dropdown-inline"
                      drop="down"
                      alignRight
                    >
                      <Dropdown.Toggle
                        as={DropdownCustomToggler}
                        id="dropdown-toggle-top"
                      >
                        <i className="ki ki-bold-more-hor" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <ul className="navi navi-hover py-5">
                          <li className="navi-item">
                            <Link
                              className="navi-link"
                              to={`${path}/${doc.id}/duzenle`}
                            >
                              <span className="navi-icon">
                                <i className="flaticon2-edit"></i>
                              </span>
                              <span className="navi-text">Düzenle</span>
                            </Link>
                          </li>
                          <li className="navi-item">
                            <a
                              href="#sil"
                              className="navi-link"
                              onClick={e => handleDelete(e, doc)}
                            >
                              <span className="navi-icon">
                                <i className="flaticon2-trash"></i>
                              </span>
                              <span className="navi-text">Sil</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <div
                      className="symbol-label"
                      style={{
                        backgroundImage: `url(${doc.get('imageURL')})`,
                      }}
                    ></div>
                  </div>
                  <div>
                    <Link
                      className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                      to={`${path}/${doc.id}`}
                    >
                      {doc.get('fullname')}
                    </Link>
                    <div className="text-muted">{ROLES[doc.get('role')]}</div>
                    <div className="mt-2">
                      <Link
                        to={`${path}/${doc.id}`}
                        className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                      >
                        Profilini Görüntüle
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="pt-8 pb-6">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Kurum:</span>
                    <span className="text-muted text-right">
                      {institutions[idx]?.get('name')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">E-posta:</span>
                    <span className="text-muted">{doc.get('email')}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Dogum tarihi:</span>
                    <span className="text-muted">
                      {moment(doc.get('dateOfBirth').toDate()).format('LL')}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Cinsiyet:</span>
                    <span className="text-muted">
                      {GENDERS[doc.get('gender')]}
                    </span>
                  </div>
                  {selectedRole !== 'student' && (
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="font-weight-bold mr-2">Branş:</span>
                      <span className="text-right">
                        {selectedRole === 'student'
                          ? doc.get('disabilities')?.map(item => (
                              <span
                                key={item}
                                className={`badge badge-${COLORS[item]} mb-1 mr-1`}
                              >
                                {DISABILITIES[item]}
                              </span>
                            ))
                          : doc.get('branches')?.map(item => (
                              <span
                                key={item}
                                className={`badge badge-${COLORS[item]} mb-1 mr-1`}
                              >
                                {BRANCHES[item]}
                              </span>
                            ))}
                      </span>
                    </div>
                  )}
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="font-weight-bold mr-2">Onay Durumu:</span>
                    <span>
                      <span
                        className={`badge badge-${
                          STATUS_COLORS[doc.get('status')]
                        } mb-1 mr-1`}
                      >
                        {STATUSES[doc.get('status')]}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default UserList;
