import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import * as ROUTES from '../constants/routes';

import Games, { GameForm } from '../components/Games';
import ScratchInfoDialog from '../components/Games/ScratchInfoDialog';

function GamesPage() {
  const [showScratchInfo, setShowScratchInfo] = useState(false);

  return (
    <>
      <ScratchInfoDialog
        isOpen={showScratchInfo}
        onToggle={() => setShowScratchInfo(false)}
        gameId={null}
      />
      <Switch>
        <Route exact path={`${ROUTES.GAMES}/ekle`}>
          <GameForm onScratchClick={() => setShowScratchInfo(true)} />
        </Route>
        <Route path={`${ROUTES.GAMES}/:id?`}>
          <Games />
        </Route>
      </Switch>
    </>
  );
}

export default GamesPage;
