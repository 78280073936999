import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { DropdownCustomToggler } from '../../_metronic/_partials/dropdowns';

import * as ROUTES from '../../constants/routes';
import COLORS from '../../constants/colors';
import { scratchURL } from '../../config';
import { SKILLS } from './constants';
import { getAuthUser, getProfile } from '../../reducers';

function GameList({ docs, onDeleteClick }) {
  const authUser = useSelector(getAuthUser);
  const profile = useSelector(getProfile);
  const role = profile.role;

  function handleDelete(event, doc) {
    event.preventDefault();
    onDeleteClick(doc);
  }

  return (
    <div className="container">
      <div className="row">
        {docs.map(doc => (
          <div key={doc.id} className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
            <div className="card card-custom gutter-b card-stretch">
              <div className="card-body pt-4">
                <div className="d-flex justify-content-end">
                  {(role === 'admin' ||
                    role === 'manager' ||
                    doc.get('createdBy').id === authUser.uid) && (
                    <Dropdown
                      className="dropdown-inline"
                      drop="down"
                      alignRight
                    >
                      <Dropdown.Toggle
                        as={DropdownCustomToggler}
                        id="dropdown-toggle-top"
                      >
                        <i className="ki ki-bold-more-hor" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                        <ul className="navi navi-hover py-5">
                          <li className="navi-item">
                            <Link
                              className="navi-link"
                              to={`${ROUTES.GAMES}/${doc.id}/duzenle`}
                            >
                              <span className="navi-icon">
                                <i className="flaticon2-edit"></i>
                              </span>
                              <span className="navi-text">Düzenle</span>
                            </Link>
                          </li>
                          <li className="navi-item">
                            <a
                              href="#sil"
                              className="navi-link"
                              onClick={e => handleDelete(e, doc)}
                            >
                              <span className="navi-icon">
                                <i className="flaticon2-trash"></i>
                              </span>
                              <span className="navi-text">Sil</span>
                            </a>
                          </li>
                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                    <div
                      className="symbol-label"
                      style={{
                        backgroundImage: `url(${doc.get('imageURL')})`,
                      }}
                    ></div>
                  </div>
                  <div>
                    <Link
                      className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                      to={`${ROUTES.GAMES}/${doc.id}`}
                    >
                      {doc.get('name')}
                    </Link>
                    <div className="text-muted">
                      by{' '}
                      <Link
                        to={`${ROUTES.TEACHERS}/${doc.get('createdBy')?.id}`}
                      >
                        {doc.get('creatorFullname') || 'Anonim'}
                      </Link>
                    </div>
                    <div className="mt-2">
                      <Link
                        to={`${ROUTES.GAMES}/${doc.id}`}
                        className="btn btn-sm btn-primary font-weight-bold mr-2 py-2 px-3 px-xxl-5 my-1"
                      >
                        Profilini Görüntüle
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="pt-8 pb-6">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">Oyunun amacı:</span>
                    <span className="text-right">{doc.get('aim')}</span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <span className="font-weight-bold mr-2">Beceriler:</span>
                    <span className="text-right">
                      {(doc.get('skills') || []).map(skill => (
                        <span
                          key={skill}
                          className={`badge badge-${COLORS[skill]} mb-1 mr-1`}
                        >
                          {SKILLS[skill]}
                        </span>
                      ))}
                    </span>
                  </div>
                </div>
                <div className="pb-6">{doc.get('description')}</div>
                <div className="pb-6">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Oynanma Sayısı:
                    </span>
                    <span className="text-muted">
                      {doc.get('numOfPlays') || 0}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Atanan Öğrenci Sayısı:
                    </span>
                    <span className="text-muted">
                      {(doc.get('assignedUsers') || []).length}
                    </span>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="font-weight-bold mr-2">
                      Oyun Değerlendirme:
                    </span>
                    <span className="text-muted">3.5</span>
                  </div>
                </div>
                <a
                  href={`${scratchURL}?userId=${authUser.uid}&gameId=${doc.id}`}
                  target="__blank"
                  className="btn btn-light-success font-weight-bold py-3 px-6 mb-2 text-center btn-block"
                >
                  Oyna
                </a>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default GameList;
