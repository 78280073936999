import React, { Suspense } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { LayoutSplashScreen, ContentRoute } from './_metronic/layout';

import * as ROUTES from './constants/routes';

// pages
import { DashboardPage } from './pages/DashboardPage';
import { MyPage } from './pages/MyPage';
import GamesPage from './pages/GamesPage';
import UsersPage from './pages/UsersPage';
import InstitutionsPage from './pages/InstitutionsPage';

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path={ROUTES.GAMES} component={GamesPage} />
        <ContentRoute path={ROUTES.STUDENTS}>
          <UsersPage selectedRole="student" />
        </ContentRoute>
        <ContentRoute path={ROUTES.TEACHERS}>
          <UsersPage selectedRole="teacher" />
        </ContentRoute>
        <ContentRoute path={ROUTES.INSTITUTIONS}>
          <InstitutionsPage />
        </ContentRoute>
        <Redirect to="/error" />
        {/* <Route path={ROUTES.HOME} exact component={Home} />
        <Route path={ROUTES.ACCOUNT} component={Account} />
        
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />
        <Route path={ROUTES.ADMIN} component={Admin} />
        <Route path={`${ROUTES.USERS}/:id?`} component={Users} />
        <Route path={`${ROUTES.GAMES}/new-game`} exact component={GameForm} />
        
        <Route path={[ROUTES.ABOUT, ROUTES.CONTACT]} component={Info} /> */}
      </Switch>
    </Suspense>
  );
}
