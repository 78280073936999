const adminPermittedRoles = ['admin', 'manager', 'teacher', 'specialist'];
const managerPermittedRoles = ['teacher', 'specialist'];

export function getPermittedRoles(userRole) {
  if (userRole === 'admin') {
    return adminPermittedRoles;
  } else if (userRole === 'manager') {
    return managerPermittedRoles;
  }
  return null;
}
