import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as ROUTES from '../constants/routes';

import Users, { UserForm } from '../components/Users';

function UsersPage({ selectedRole }) {
  const path = `${
    selectedRole === 'student' ? ROUTES.STUDENTS : ROUTES.TEACHERS
  }`;
  return (
    <Switch>
      <Route exact path={`${path}/ekle`}>
        <UserForm selectedRole={selectedRole} path={path} />
      </Route>
      <Route path={`${path}/:id?`}>
        <Users selectedRole={selectedRole} path={path} />
      </Route>
    </Switch>
  );
}

export default UsersPage;
