import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import 'moment/locale/tr';

import { fetchDocs, updateDoc } from '../../actions';
import {
  getDocs,
  getIsFetching,
  getFetchError,
  getDocsById,
  getIds,
  getProfile,
} from '../../reducers';
import Spinner from '../Spinner';
import Error from '../Error';
import { usersIndex, usersPath } from '.';

import addGraphIcon from './add-graph.png';

moment.locale('tr');

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {
    source: sourceClone,
    dest: destClone,
  };

  return result;
};

const COLORS = ['#06d6a0', '#118ab2', '#073b4c', '#ffd166', '#ef476f'];

const CHARTS = ['Çizgi Grafik', 'Çubuk Grafik', 'Pasta Grafik', 'Alan Grafiği'];

export const sessionsPath = '/sessions';
export const getSessions = query => state => ({
  docs: getDocs(state, query),
  byId: getDocsById(state, query),
  ids: getIds(state, query),
  isFetching: getIsFetching(state, query),
  fetchError: getFetchError(state, query),
});

function GameCharts({ user, onSessionsFetched }) {
  const profile = useSelector(getProfile);
  const isStudent = profile.role === 'student';
  const dispatch = useDispatch();
  const query = useMemo(
    () => ({
      path: sessionsPath,
      where: [['user', '==', user.ref]],
    }),
    []
  );
  useEffect(() => {
    dispatch(fetchDocs(query));
  }, [dispatch, query]);

  const sessionsSelector = useCallback(state => getSessions(query)(state), [
    query,
  ]);
  const {
    docs: sessions,
    byId: sessionsById,
    isFetching,
    fetchError,
  } = useSelector(sessionsSelector);
  const [sessionIdsByGameId, setSessionIdsByGameId] = useState({});
  const [gamesById, setGamesById] = useState({});
  const [list1, setList1] = useState([]);
  const [list2, setList2] = useState([]);
  useEffect(() => {
    const graphState = user.get('graphState') || { list1: [], list2: [] };
    setList1(graphState.list1);
    setList2(graphState.list2);
  }, [user]);
  useEffect(() => {
    if (sessions && sessions.length > 0) {
      // report sessions to the parent
      onSessionsFetched(sessions);
      // create session lists collated by game ids
      const sessionIdsByGameId = (sessions || []).reduce((obj, session) => {
        const gameRef = session.get('game');
        if (!obj[gameRef.id]) {
          obj[gameRef.id] = [];
        }
        obj[gameRef.id].push(session.id);
        return obj;
      }, {});
      setSessionIdsByGameId(sessionIdsByGameId);
      // update graph sessions
      setList1(list1 =>
        list1.map(item => ({
          ...item,
          gameSessionIds: sessionIdsByGameId[item.gameId] || [],
        }))
      );
      setList2(list2 =>
        list2.map(item => ({
          ...item,
          gameSessionIds: sessionIdsByGameId[item.gameId],
        }))
      );
      // create game list
      const gameRefs = (sessions || []).reduce((obj, session) => {
        const gameRef = session.get('game');
        obj[gameRef.id] = gameRef;
        return obj;
      }, {});
      Promise.all(Object.values(gameRefs).map(ref => ref.get())).then(
        sessions => {
          const gamesById = sessions.reduce((obj, doc) => {
            obj[doc.id] = doc;
            return obj;
          }, {});
          setGamesById(gamesById);
        }
      );
    }
  }, [sessions]);

  function getList(listId) {
    return listId === 'list1' ? [list1, setList1] : [list2, setList2];
  }

  function getLabels(sessionId) {
    return sessionsById[sessionId].get('labels').reduce((obj, label) => {
      obj[label] = true;
      return obj;
    }, {});
  }

  async function saveGraphState(list1, list2) {
    if (isStudent) {
      return;
    }
    const query = { path: usersPath };
    const newDoc = await dispatch(
      updateDoc(query, user.id, { graphState: { list1, list2 } })
    );
    await usersIndex.update(newDoc.id, newDoc.data());
  }

  function handleDragEnd(result) {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const [list, setList] = getList(source.droppableId);
      const reordered = reorder(list, source.index, destination.index);
      setList(reordered);
      if (source.droppableId === 'list1') {
        saveGraphState(reordered, list2);
      } else {
        saveGraphState(list1, reordered);
      }
    } else {
      const [sourceList, setSourceList] = getList(source.droppableId);
      const [destList, setDestList] = getList(destination.droppableId);
      const result = move(sourceList, destList, source, destination);
      setSourceList(result.source);
      setDestList(result.dest);
      if (source.droppableId === 'list1') {
        saveGraphState(result.source, result.dest);
      } else {
        saveGraphState(result.dest, result.source);
      }
    }
  }

  const { addToast } = useToasts();
  function handleNewGraph() {
    if (!sessions || sessions.length === 0) {
      addToast(
        'Henüz hiç oyun oynanmadığı için grafik ekleyemiyorsunuz',
        { appearance: 'warning' }
      );
      return;
    }
    const gameId = Object.keys(gamesById)[0];
    const gameSessionIds = sessionIdsByGameId[gameId];
    const sessionId = gameSessionIds[0];
    const labels = getLabels(sessionId);
    const id = `graph-${list1.length + list2.length}`;
    const chartTypeIdx = 0;
    const graph = {
      id,
      gameId,
      gameSessionIds,
      sessionId,
      labels,
      chartTypeIdx,
    };
    if (list1.length <= list2.length) {
      const newList1 = [...list1, graph];
      setList1(newList1);
      saveGraphState(newList1, list2);
    } else {
      const newList2 = [...list2, graph];
      setList2(newList2);
      saveGraphState(list1, newList2);
    }
  }

  function handleDeleteGraph(listId, index) {
    const [list, setList] = getList(listId);
    const newList = list.filter((item, idx) => idx !== index);
    setList(newList);

    if (listId === 'list1') {
      saveGraphState(newList, list2);
    } else {
      saveGraphState(list1, newList);
    }
  }

  function handleGameSelect(listId, index, gameId) {
    const [list, setList] = getList(listId);
    const gameSessionIds = sessionIdsByGameId[gameId];
    const sessionId = gameSessionIds[0];
    const labels = getLabels(sessionId);
    const graph = { ...list[index], gameId, gameSessionIds, sessionId, labels };
    const newList = list.map((item, idx) => (idx === index ? graph : item));
    setList(newList);

    if (listId === 'list1') {
      saveGraphState(newList, list2);
    } else {
      saveGraphState(list1, newList);
    }
  }

  function handleSessionSelect(listId, index, sessionId) {
    const [list, setList] = getList(listId);
    const labels = getLabels(sessionId);
    const graph = { ...list[index], sessionId, labels };
    const newList = list.map((item, idx) => (idx === index ? graph : item));
    setList(newList);

    if (listId === 'list1') {
      saveGraphState(newList, list2);
    } else {
      saveGraphState(list1, newList);
    }
  }

  function handleChartTypeSelect(listId, index, chartTypeIdx) {
    const [list, setList] = getList(listId);
    const graph = { ...list[index], chartTypeIdx: parseInt(chartTypeIdx) };
    const newList = list.map((item, idx) => (idx === index ? graph : item));
    setList(newList);

    if (listId === 'list1') {
      saveGraphState(newList, list2);
    } else {
      saveGraphState(list1, newList);
    }
  }

  function handleLabelChange(listId, index, label) {
    const [list, setList] = getList(listId);
    const labels = { ...list[index].labels };
    labels[label] = !labels[label];
    const graph = { ...list[index], labels };
    const newList = list.map((item, idx) => (idx === index ? graph : item));
    setList(newList);

    if (listId === 'list1') {
      saveGraphState(newList, list2);
    } else {
      saveGraphState(list1, newList);
    }
  }

  function renderGraph(chartTypeIdx, data, labels) {
    let content;
    if (chartTypeIdx === 0) {
      content = (
        <LineChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {Object.keys(labels)
            .filter(label => labels[label])
            .map((label, labelIdx) => (
              <Line
                key={label}
                type="monotone"
                dataKey={label}
                stroke={COLORS[labelIdx]}
                activeDot={{ r: 8 }}
              />
            ))}
        </LineChart>
      );
    } else if (chartTypeIdx === 1) {
      content = (
        <BarChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {Object.keys(labels)
            .filter(label => labels[label])
            .map((label, labelIdx) => (
              <Bar key={label} dataKey={label} fill={COLORS[labelIdx]} />
            ))}
        </BarChart>
      );
    } else if (chartTypeIdx === 2) {
      content = (
        <PieChart
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <Tooltip />
          {Object.keys(labels)
            .filter(label => labels[label])
            .map((label, labelIdx) => (
              <Pie
                key={label}
                data={data}
                dataKey={label}
                nameKey="date"
                innerRadius={labelIdx * 40}
                outerRadius={(labelIdx + 1) * 40 - 5}
                fill={COLORS[labelIdx]}
              />
            ))}
        </PieChart>
      );
    } else if (chartTypeIdx === 3) {
      content = (
        <AreaChart
          data={data}
          margin={{
            top: 5,
            right: 5,
            left: 5,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {Object.keys(labels)
            .filter(label => labels[label])
            .map((label, labelIdx) => (
              <Area
                key={label}
                type="monotone"
                dataKey={label}
                fill={COLORS[labelIdx]}
                fillOpacity={1}
              />
            ))}
        </AreaChart>
      );
    }
    return content;
  }

  function renderList(listId) {
    const [list, setList] = getList(listId);
    return (
      <Droppable key={listId} droppableId={listId}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className="col-lg-6">
            {list.filter((graph, graphIdx) => !!sessionsById[graph.sessionId]).map((graph, graphIdx) => (
              <Draggable key={graph.id} draggableId={graph.id} index={graphIdx}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="card card-custom gutter-b"
                  >
                    <div className="card-header">
                      <div className="card-title">
                        <select
                          className="custom-select mr-2"
                          data-width="200px"
                          title="Oyunlar"
                          value={graph.gameId}
                          onChange={e =>
                            handleGameSelect(listId, graphIdx, e.target.value)
                          }
                        >
                          {Object.keys(gamesById).map(id => (
                            <option key={id} value={id}>
                              {gamesById[id].get('name')}
                            </option>
                          ))}
                        </select>
                        <select
                          className="custom-select mr-2"
                          data-width="200px"
                          title="Oturum"
                          value={graph.sessionId}
                          onChange={e =>
                            handleSessionSelect(
                              listId,
                              graphIdx,
                              e.target.value
                            )
                          }
                        >
                          {graph.gameSessionIds.filter(id => !!sessionsById[id]).map(id => (
                            <option key={id} value={id}>
                              {moment(
                                sessionsById[id].get('startedAt').toDate()
                              ).format('llll')}
                            </option>
                          ))}
                        </select>
                        <select
                          className="custom-select"
                          data-width="200px"
                          title="Grafik Turu"
                          value={graph.chartTypeIdx}
                          onChange={e =>
                            handleChartTypeSelect(
                              listId,
                              graphIdx,
                              e.target.value
                            )
                          }
                        >
                          {CHARTS.map((chart, chartIdx) => (
                            <option key={chart} value={chartIdx}>
                              {chart}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="card-toolbar">
                        <button
                          className="btn btn-icon btn-sm btn-hover-light-primary draggable-handle"
                          onClick={() => handleDeleteGraph(listId, graphIdx)}
                        >
                          <i className="ki ki-close"></i>
                        </button>
                      </div>
                    </div>
                    <div className="card-body text-center">
                      <ResponsiveContainer width="100%" aspect={3 / 2}>
                        {renderGraph(
                          graph.chartTypeIdx,
                          sessionsById[graph.sessionId].get('data'),
                          graph.labels
                        )}
                      </ResponsiveContainer>
                      {Object.keys(graph.labels).map((label, labelIdx) => (
                        <div
                          key={`${listId}_${graphIdx}_${label}`}
                          className="custom-control custom-checkbox custom-control-inline"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={`${listId}_${graphIdx}_${label}`}
                            checked={graph.labels[label]}
                            onChange={e =>
                              handleLabelChange(listId, graphIdx, label)
                            }
                          />
                          <label
                            className="custom-control-label"
                            style={{ color: COLORS[labelIdx] }}
                            htmlFor={`${listId}_${graphIdx}_${label}`}
                          >
                            {label}
                          </label>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }

  if (isFetching || !sessions) {
    return <Spinner />;
  }
  if (fetchError) {
    return <Error error={fetchError} />;
  }

  return (
    <>
      <div className="card card-custom card-stretch gutter-b" id="grafikler">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Grafikler
            </span>
            <span className="text-muted mt-3 font-weight-bold font-size-sm">
              İstediğiniz veriyi, istediğiniz grafik türünde görüntülemek için
              yeni grafik butonuna tıklayın
            </span>
          </h3>
        </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className="row">
          {['list1', 'list2'].map(listId => renderList(listId))}
        </div>
      </DragDropContext>
      <div className="row">
        <div className="col-lg-6">
          <div className="card card-custom gutter-b">
            <div className="card-body text-center">
              <a
                href="#drag"
                className="btn btn-hover-light-primary stretched-link"
                onClick={e => {
                  e.preventDefault();
                  handleNewGraph();
                }}
              >
                <img
                  className="ki icon-8x"
                  src={addGraphIcon}
                  alt="Yeni Grafik"
                />{' '}
                <span className="h1">Yeni Grafik</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GameCharts;
