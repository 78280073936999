import { getAuthUser } from '../reducers';

export const setAuthUser = (authUser, profile) => ({
  type: 'SET_AUTH_USER',
  authUser,
  profile,
});

export const updatePassword = password => (dispatch, getState, api) => {
  dispatch({ type: 'UPDATE_PASSWORD_REQUEST' });
  return api
    .updatePassword(password)
    .then(() => dispatch({ type: 'UPDATE_PASSWORD_SUCCESS' }))
    .catch(error => {
      dispatch({ type: 'UPDATE_PASSWORD_FAILURE', error });
      return Promise.reject(error);
    });
};

export const forgetPassword = email => (dispatch, getState, api) => {
  dispatch({ type: 'FORGET_PASSWORD_REQUEST' });
  return api
    .sendPasswordResetEmail(email)
    .then(() => dispatch({ type: 'FORGET_PASSWORD_SUCCESS' }))
    .catch(error => {
      dispatch({ type: 'FORGET_PASSWORD_FAILURE', error });
      return Promise.reject(error);
    });
};

export const setProfile = profile => ({
  type: 'SET_PROFILE',
  profile,
});

export const refreshProfile = () => (dispatch, getState, api) => {
  let authUser = getAuthUser(getState());
  return api.getUser(authUser.uid).then(user => {
    return dispatch(setProfile(user));
  });
};

export const updateProfile = data => (dispatch, getState, api) => {
  dispatch({ type: 'UPDATE_PROFILE_REQUEST' });
  return api
    .updateUser(data)
    .then(() => dispatch({ type: 'UPDATE_PROFILE_SUCCESS', profile: data }))
    .catch(error => {
      dispatch({ type: 'UPDATE_PROFILE_FAILURE', error });
      return Promise.reject(error);
    });
};

export const signUp = data => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_UP_REQUEST' });
  return api.signUp(data).then(
    () => {
      dispatch({ type: 'SIGN_UP_SUCCESS' });
      return Promise.resolve();
    },
    error => {
      dispatch({
        type: 'SIGN_UP_FAILURE',
        error,
      });
      return Promise.reject(error);
    }
  );
};

export const signIn = (email, password) => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_IN_REQUEST' });
  return api.signInWithEmailAndPassword(email, password).then(
    authUser => {
      dispatch({
        type: 'SIGN_IN_SUCCESS',
        authUser,
      });
      return Promise.resolve(true);
    },
    error => {
      dispatch({
        type: 'SIGN_IN_FAILURE',
        error,
      });
      return Promise.resolve(false);
    }
  );
};

export const signInWithProvider = provider => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_IN_REQUEST' });
  let response;
  switch (provider) {
    case 'google':
      response = api.signInWithGoogle();
      break;
    case 'facebook':
      response = api.signInWithFacebook();
      break;
    case 'twitter':
      response = api.signInWithTwitter();
      break;
    default:
      return Promise.resolve(false);
  }
  return response.then(
    result => {
      dispatch({
        type: 'SIGN_IN_SUCCESS',
        authUser: result.user,
      });
      return Promise.resolve(true);
    },
    error => {
      dispatch({
        type: 'SIGN_IN_FAILURE',
        error,
      });
      return Promise.resolve(false);
    }
  );
};

export const signOut = () => (dispatch, getState, api) => {
  dispatch({ type: 'SIGN_OUT_REQUEST' });
  return api.signOut().then(
    () =>
      dispatch({
        type: 'SIGN_OUT_SUCCESS',
      }),
    error =>
      dispatch({
        type: 'SIGN_OUT_FAILURE',
        error,
      })
  );
};

export const deleteAccount = () => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'DELETE_ACCOUNT_REQUEST' });
    await api.deleteAccount();
    return dispatch({
      type: 'DELETE_ACCOUNT_SUCCESS',
    });
  } catch (error) {
    dispatch({
      type: 'DELETE_ACCOUNT_FAILURE',
      error,
    });
    return Promise.reject(error);
  }
};

export const fetchDocs = query => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'FETCH_DOCS_REQUEST', query });
    const docs = await api.fetchDocs(query.path, query);
    dispatch({ type: 'FETCH_DOCS_SUCCESS', query, docs });
    return docs;
  } catch (error) {
    dispatch({ type: 'FETCH_DOCS_FAILURE', query, error });
    throw error;
  }
};

export const fetchDoc = (query, id) => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'FETCH_DOC_REQUEST', id });
    const doc = await api.fetchDoc(query.path, id);
    dispatch({ type: 'FETCH_DOC_SUCCESS', query, doc });
    return doc;
  } catch (error) {
    dispatch({ type: 'FETCH_DOC_FAILURE', query, error });
    throw error;
  }
};

export const addDoc = (query, data) => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'ADD_DOC_REQUEST' });
    const doc = await api.addDoc(query.path, data);
    dispatch({ type: 'ADD_DOC_SUCCESS', query, doc });
    return doc;
  } catch (error) {
    dispatch({ type: 'ADD_DOC_FAILURE', query, error });
    throw error;
  }
};

export const updateDoc = (query, id, data) => async (
  dispatch,
  getState,
  api
) => {
  try {
    dispatch({ type: 'UPDATE_DOC_REQUEST', id });
    const doc = await api.updateDoc(query.path, id, data);
    dispatch({ type: 'UPDATE_DOC_SUCCESS', query, doc });
    return doc;
  } catch (error) {
    dispatch({ type: 'UPDATE_DOC_FAILURE', query, error });
    throw error;
  }
};

export const deleteDoc = (query, id) => async (dispatch, getState, api) => {
  try {
    dispatch({ type: 'DELETE_DOC_REQUEST', id });
    await api.deleteDoc(query.path, id);
    dispatch({ type: 'DELETE_DOC_SUCCESS', query, id });
  } catch (error) {
    dispatch({ type: 'DELETE_DOC_FAILURE', query, error });
    throw error;
  }
};
