import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useForm } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import * as ROUTES from '../../constants/routes';
import { useFirebase } from '../../hooks';
import { addDoc, updateDoc } from '../../actions';
import { getAuthUser, getProfile } from '../../reducers';
import Button from '../Button';
import { SKILLS, STATUSES } from './constants';
import { gamesIndex, gamesPath } from '.';

function GameForm({ doc, onScratchClick }) {
  const {
    register,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    setValue,
    watch,
  } = useForm({
    defaultValues: doc ? doc.data() : {},
  });

  useEffect(() => {
    register({ name: 'filePath' }, { required: true });
    register({ name: 'imagePath' }, { required: true });
    register({ name: 'imageURL' }, { required: true });
  }, [register]);

  const { addToast } = useToasts();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);

  const [image, setImage] = useState();
  function loadImage(selectedFile) {
    const reader = new FileReader();
    reader.onload = event => {
      setImage(event.target.result);
    };
    reader.onerror = event => {
      addToast('Selected file could not read.', {
        appearance: 'error',
      });
      reader.abort();
    };
    reader.readAsDataURL(selectedFile);
  }

  async function handleImageChange(event) {
    const selectedFile = event.target.files[0];
    if (selectedFile.size > 10 * 1024 * 1024) {
      setError('imagePath', 'fileSize');
      return;
    }
    clearErrors('imagePath');

    setUploading(true);
    loadImage(selectedFile);
    const { filePath, fileURL } = await uploadFile(
      selectedFile,
      'imagePath',
      'games/images'
    );
    setValue('imagePath', filePath);
    setValue('imageURL', fileURL);
    setUploading(false);
  }

  async function handleFileChange(event) {
    const selectedFile = event.target.files[0];
    if (!selectedFile.name.match(/\.sb3/)) {
      setError('filePath', 'fileType');
      return;
    }
    if (selectedFile.size > 10 * 1024 * 1024) {
      setError('filePath', 'fileSize');
      return;
    }
    clearErrors('filePath');

    setUploading(true);
    const { filePath } = await uploadFile(
      selectedFile,
      'filePath',
      'games/files'
    );
    setValue('filePath', filePath);
    setUploading(false);
  }

  const firebase = useFirebase();
  const authUser = useSelector(getAuthUser);
  async function uploadFile(file, fieldName, path) {
    const storageRef = firebase.storage.ref();

    // upload file if exists
    const userId = authUser.uid;
    const filePath =
      doc && doc.get(fieldName)
        ? doc.get(fieldName)
        : `${userId}/${path}/${uuidv4()}`;
    const fileRef = storageRef.child(filePath);
    await fileRef.put(file);
    const fileURL = await fileRef.getDownloadURL();
    return { filePath, fileURL };
  }

  const dispatch = useDispatch();
  const profile = useSelector(getProfile);
  async function onSubmit(data) {
    try {
      setLoading(true);

      data.creatorFullname = profile.fullname;
      data.status = "1";
      data.ready = true;

      let newDoc;
      let message;
      const query = { path: gamesPath };
      if (doc) {
        newDoc = await dispatch(updateDoc(query, doc.id, data));
        await gamesIndex.update(newDoc.id, newDoc.data());
        message = 'Oyun başarıyla güncellendi';
      } else {
        newDoc = await dispatch(addDoc(query, data));
        await gamesIndex.save(newDoc.id, newDoc.data());
        message = 'Oyun başarıyla yüklendi';
      }
      addToast(message, { appearance: 'success' });
      history.push(`${ROUTES.GAMES}/${newDoc.id}`);
    } catch (error) {
      addToast(error.message, { appearance: 'error' });
      setLoading(false);
    }
  }

  const imageSrc = image || watch('imageURL');
  return (
    <div className="flex-row-fluid ml-lg-8">
      <form className="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="card card-custom card-stretch">
          <div className="card-header py-3">
            <div className="card-title align-items-start flex-column">
              <h3 className="card-label font-weight-bolder text-dark">
                {doc ? 'Düzenle' : 'Yeni Oyun'}
              </h3>
              <span className="text-muted font-weight-bold font-size-sm mt-1">
                {doc ? 'Oyunu düzenle' : 'Yeni oyun ekle'}
              </span>
            </div>
            <div className="card-toolbar">
              <button
                type="button"
                className="btn btn-primary mr-2"
                onClick={onScratchClick}
              >
                Oyun Tasarla
              </button>
              <Button className="btn btn-success mr-2" loading={loading}>
                {doc ? 'Kaydet' : 'Ekle'}
              </Button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => history.goBack()}
              >
                İptal
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Oyun görseli
              </label>
              <div className="col-lg-9 col-xl-6">
                <div
                  className="image-input image-input-outline"
                  id="kt_profile_avatar"
                  style={{
                    backgroundImage:
                      'url(/media/svg/icons/Devices/Gamepad1.svg)',
                  }}
                >
                  {imageSrc ? (
                    <img
                      src={imageSrc}
                      className="image-input-wrapper"
                      alt="Oyun görseli"
                    />
                  ) : (
                    <div className="image-input-wrapper"></div>
                  )}
                  <label
                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                    data-action="change"
                    data-toggle="tooltip"
                    title=""
                    data-original-title="Görseli değiştir"
                  >
                    <i className="fa fa-pen icon-sm text-muted"></i>
                    <input
                      type="file"
                      id="image"
                      name="image"
                      accept=".png, .jpg, .jpeg"
                      onChange={handleImageChange}
                    />
                    <input type="hidden" name="image" />
                  </label>
                </div>
                <span className="form-text text-muted">
                  İzin verilen dosya türleri: png, jpg, jpeg.
                </span>
                {errors.imagePath && (
                  <div className="text-danger">
                    {errors.imagePath?.type === 'fileSize'
                      ? "Resim boyutu 10MB'dan az olmalı."
                      : 'Gerekli alan'}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Oyun adı
              </label>
              <div className="col-lg-9 col-xl-6">
                <input
                  type="text"
                  className={`form-control form-control-lg form-control-solid ${errors.name &&
                    'is-invalid'}`}
                  id="name"
                  name="name"
                  ref={register({ required: true })}
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Oyunun amacı
              </label>
              <div className="col-lg-9 col-xl-6">
                <textarea
                  className={`form-control form-control-lg form-control-solid ${errors.aim &&
                    'is-invalid'}`}
                  id="aim"
                  name="aim"
                  ref={register({ required: true })}
                  rows="2"
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Açıklama
              </label>
              <div className="col-lg-9 col-xl-6">
                <textarea
                  className={`form-control form-control-lg form-control-solid ${errors.description &&
                    'is-invalid'}`}
                  id="description"
                  name="description"
                  ref={register({ required: true })}
                  rows="5"
                />
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Beceriler
              </label>
              <div className="col-lg-9 col-xl-6">
                <select
                  className={`form-control form-control-lg form-control-solid ${errors.skills &&
                    'is-invalid'}`}
                  id="skills"
                  name="skills"
                  ref={register({ required: true })}
                  multiple
                >
                  {SKILLS.map((item, idx) => (
                    <option key={item} value={idx}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Oyun dosyası
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="custom-file form-control-lg form-control-solid">
                  <input
                    type="file"
                    id="file"
                    name="file"
                    onChange={handleFileChange}
                    accept=".sb3"
                    className={`custom-file-input ${errors.filePath &&
                      'is-invalid'}`}
                  />
                  <label className="custom-file-label" htmlFor="file">
                    {uploading
                      ? 'Yükleniyor...'
                      : watch('filePath')
                      ? 'Eklendi'
                      : 'Dosya seç'}
                  </label>
                  <div className="invalid-feedback">
                    {errors.filePath?.type === 'fileType'
                      ? 'Seçilen dosya Scratch (.sb3) dosyası olmalı.'
                      : errors.filePath?.type === 'fileSize'
                      ? "Dosya boyutu 10MB'dan az olmalı."
                      : 'Gerekli alan'}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="form-group row">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Kabul durumu
              </label>
              <div className="col-lg-9 col-xl-6">
                <select
                  className={`custom-select form-control form-control-lg form-control-solid ${errors.status &&
                    'is-invalid'}`}
                  id="status"
                  name="status"
                  ref={register({ required: true })}
                >
                  {STATUSES.map((item, idx) => (
                    <option key={item} value={idx}>
                      {item}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div>
            <div className="form-group row align-items-center">
              <label className="col-xl-3 col-lg-3 col-form-label text-right">
                Oyununuz yayınlanmak üzere uzmana gönderilsin mi?
              </label>
              <div className="col-lg-9 col-xl-6">
                <div className="checkbox-inline">
                  <label className="checkbox m-0">
                    <input
                      type="checkbox"
                      className={`form-control form-control-lg form-control-solid ${errors.status &&
                        'is-invalid'}`}
                      id="ready"
                      name="ready"
                      ref={register()}
                    />
                    <span></span>Evet
                  </label>
                </div>
                <div className="invalid-feedback">Gerekli alan</div>
              </div>
            </div> */}
          </div>
        </div>
      </form>
    </div>
  );
}

export default GameForm;
