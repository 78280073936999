import React from 'react';
import { Switch, Route } from 'react-router-dom';

import * as ROUTES from '../constants/routes';

import Institutions, { InstitutionForm } from '../components/Institutions';

function InstitutionsPage() {
  return (
    <Switch>
      <Route exact path={`${ROUTES.INSTITUTIONS}/ekle`}>
        <InstitutionForm />
      </Route>
      <Route path={`${ROUTES.INSTITUTIONS}/:id?`}>
        <Institutions />
      </Route>
    </Switch>
  );
}

export default InstitutionsPage;
