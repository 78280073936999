import { combineReducers } from 'redux';

const createCollection = path => {
  const byId = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'FETCH_DOCS_SUCCESS': {
        const queryDocs = { ...state[queryId] };
        action.docs.forEach(doc => {
          queryDocs[doc.id] = doc;
        });
        return { ...state, [queryId]: queryDocs };
      }
      case 'FETCH_DOC_SUCCESS':
      case 'ADD_DOC_SUCCESS':
      case 'UPDATE_DOC_SUCCESS': {
        const queryDocs = { ...state[queryId] };
        queryDocs[action.doc.id] = action.doc;
        return { ...state, [queryId]: queryDocs };
      }
      default:
        return state;
    }
  };

  const ids = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'FETCH_DOCS_SUCCESS': {
        let queryIds = action.docs.map(doc => doc.id);
        return { ...state, [queryId]: queryIds };
      }
      case 'FETCH_DOC_SUCCESS':
      case 'ADD_DOC_SUCCESS':
      case 'UPDATE_DOC_SUCCESS': {
        let queryIds = state[queryId] || [];
        if (queryIds.includes(action.doc.id)) {
          return state;
        }
        queryIds = [...queryIds, action.doc.id];
        return { ...state, [queryId]: queryIds };
      }
      case 'DELETE_DOC_SUCCESS': {
        let queryIds = state[queryId] || [];
        return { ...state, [queryId]: queryIds.filter(id => id !== action.id) };
      }
      default:
        return state;
    }
  };

  const isFetching = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'FETCH_DOCS_REQUEST':
      case 'FETCH_DOC_REQUEST':
        return { ...state, [queryId]: true };
      case 'FETCH_DOCS_SUCCESS':
      case 'FETCH_DOCS_FAILURE':
      case 'FETCH_DOC_SUCCESS':
      case 'FETCH_DOC_FAILURE':
        return { ...state, [queryId]: false };
      default:
        return state;
    }
  };

  const isAdding = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'ADD_DOC_REQUEST':
        return { ...state, [queryId]: true };
      case 'ADD_DOC_SUCCESS':
      case 'ADD_DOC_FAILURE':
        return { ...state, [queryId]: false };
      default:
        return state;
    }
  };

  const isUpdating = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'UPDATE_DOC_REQUEST':
        return { ...state, [queryId]: true };
      case 'UPDATE_DOC_SUCCESS':
      case 'UPDATE_DOC_FAILURE':
        return { ...state, [queryId]: false };
      default:
        return state;
    }
  };

  const isDeleting = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'DELETE_DOC_REQUEST':
        return { ...state, [queryId]: true };
      case 'DELETE_DOC_SUCCESS':
      case 'DELETE_DOC_FAILURE':
        return { ...state, [queryId]: false };
      default:
        return state;
    }
  };

  const fetchError = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'FETCH_DOCS_FAILURE':
      case 'FETCH_DOC_FAILURE':
        return { ...state, [queryId]: action.error };
      case 'FETCH_DOCS_REQUEST':
      case 'FETCH_DOCS_SUCCESS':
      case 'FETCH_DOC_REQUEST':
      case 'FETCH_DOC_SUCCESS':
        return { ...state, [queryId]: null };
      default:
        return state;
    }
  };

  const addError = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'ADD_DOC_FAILURE':
        return { ...state, [queryId]: action.error };
      case 'ADD_DOC_REQUEST':
      case 'ADD_DOC_SUCCESS':
        return { ...state, [queryId]: null };
      default:
        return state;
    }
  };

  const updateError = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'UPDATE_DOC_FAILURE':
        return { ...state, [queryId]: action.error };
      case 'UPDATE_DOC_REQUEST':
      case 'UPDATE_DOC_SUCCESS':
        return { ...state, [queryId]: null };
      default:
        return state;
    }
  };

  const deleteError = (state = {}, action) => {
    if (path !== action.query?.path) {
      return state;
    }
    let queryId = action.query.id;
    if (!queryId) {
      queryId = '/';
    }
    switch (action.type) {
      case 'DELETE_DOC_FAILURE':
        return { ...state, [queryId]: action.error };
      case 'DELETE_DOC_REQUEST':
      case 'DELETE_DOC_SUCCESS':
        return { ...state, [queryId]: null };
      default:
        return state;
    }
  };

  return combineReducers({
    byId,
    ids,
    isFetching,
    isAdding,
    isUpdating,
    isDeleting,
    fetchError,
    addError,
    updateError,
    deleteError,
  });
};

export default createCollection;

export const getIds = (state, query) => state[query.path].ids[query.id || '/'];
export const getDocs = (state, query) => {
  const ids = state[query.path].ids[query.id || '/'];
  return ids
    ? ids.map(id => state[query.path].byId[query.id || '/'][id])
    : null;
};
export const getDocsById = (state, query) =>
  state[query.path].byId[query.id || '/'];
export const getDoc = (state, query, id) => {
  const byId = state[query.path].byId[query.id || '/'];
  return byId ? byId[id] : null;
};
export const getIsFetching = (state, query) =>
  state[query.path].isFetching[query.id || '/'];
export const getIsAdding = (state, query) =>
  state[query.path].isAdding[query.id || '/'];
export const getIsUpdating = (state, query) =>
  state[query.path].isUpdating[query.id || '/'];
export const getIsDeleting = (state, query) =>
  state[query.path].isDeleting[query.id || '/'];
export const getFetchError = (state, query) =>
  state[query.path].fetchError[query.id || '/'];
export const getAddError = (state, query) =>
  state[query.path].addError[query.id || '/'];
export const getUpdateError = (state, query) =>
  state[query.path].updateError[query.id || '/'];
export const getDeleteError = (state, query) =>
  state[query.path].deleteError[query.id || '/'];
