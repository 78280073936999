export const HOME = '/';
export const ACCOUNT = '/account';
export const ACCOUNT_SETTINGS = ACCOUNT + '/settings';
export const ACCOUNT_DELETE = ACCOUNT + '/delete';
export const ACCOUNT_PASSWORD_CHANGE = ACCOUNT + '/password-change';
export const SIGNUP = '/auth/signup';
export const SIGNIN = '/auth/signin';
export const SIGNOUT = '/auth/signout';
export const PASSWORD_FORGET = '/auth/password-forget';
export const ADMIN = '/admin';
export const ABOUT = '/about';
export const CONTACT = '/contact-us';
export const GAMES = '/oyunlar';
export const STUDENTS = '/ogrenciler';
export const TEACHERS = '/ogretmenler';
export const INSTITUTIONS = '/kurumlar';
